import React, { useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import toFixedFloat from '../helpers/toFixedFloat';
import { useSelector } from 'react-redux';
// import { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE } from '../constants/sales';
import { useConfigData } from '../Context/ConfigProvider';

const Price = React.memo(
  ({
    above,
    productDetails: {
      product_currency: productCurrency,
      category_currency: categoryCurrency,
      product_details_id: productId,
      price = 0,
      asap,
      schedule,
      menu,
      bulk_base_price: bulkBasePrice,
      product_name: productName,
      concentrated_weight,
      non_concentrated_weight
    },
    priceClassName,
    orignalPrice = 0,
    categorySalePriceId = null,
    quantity = 1,
    hideStrikePrice = false,
    preventDiscountFormat = false,
    otherAttributes,
    noTaxCalculation

  }) => {
    const couponData = useSelector(state => state.user.couponData);
    const [discountedPrice = 0, setDiscountedPrice] = useState(price);
    const currency = productCurrency || categoryCurrency;
    const appConfig = useConfigData();
    const { EXCISE_TAX_RATE = 0, LOCAL_CANNABIS_TAX_RATE = 0, SALES_TAX_RATE = 0 } = appConfig
    const calculateTax = appConfig.DISPLAY_TAXED_PRICE && !noTaxCalculation

    function applyDiscount() {
      const { discount = 0, slash_price: slashPrice } = couponData;
      if (slashPrice) {
        switch (Number(couponData.eligible_delivery_type)) {
          case 1:
            if (asap && menu !== 2) {
              return (price * ((100 - discount) / 100)).toFixed(2);
            }
            return price;
          case 2:
            if (schedule && menu !== 1) {
              return (price * ((100 - discount) / 100)).toFixed(2);
            }
            return price;
          default:
            return (price * ((100 - discount) / 100)).toFixed(2);
        }
      } else {
        return price;
      }
    }

    useEffect(() => {
      const calculatePrice = () => {
        let newDiscountedPrice = price;
        if ((orignalPrice && orignalPrice !== price && !bulkBasePrice) || categorySalePriceId !== null) {
          newDiscountedPrice = price;
        } else if (Array.isArray(couponData.product_ids) && couponData.product_ids.length) {
          if (findIndex(couponData.product_ids, o => parseInt(o, 10) === parseInt(productId, 10)) > -1) {
            newDiscountedPrice = applyDiscount();
          }
        } else {
          newDiscountedPrice = price;
        }
        setDiscountedPrice(parseFloat(newDiscountedPrice));
      };
      calculatePrice();
    }, [couponData, price, productId, orignalPrice, bulkBasePrice, menu]);

    // Determine if the product is cannabis-based
    const isCannabisProduct = concentrated_weight > 0 || non_concentrated_weight > 0;

    // Helper function to apply tax to a given price
    const applyTax = (basePrice) => {
      let priceWithTax = basePrice;
      if (calculateTax) {
        if (isCannabisProduct) {
          priceWithTax += priceWithTax * EXCISE_TAX_RATE;
          priceWithTax += priceWithTax * LOCAL_CANNABIS_TAX_RATE;
        }
        priceWithTax += priceWithTax * SALES_TAX_RATE;
      }
      return priceWithTax;
    };

    // Calculate final prices with taxes if applicable
    const finalDiscountedPrice = applyTax(discountedPrice * quantity);
    const finalOrignalPrice = orignalPrice ? applyTax(orignalPrice * quantity) : applyTax(price * quantity);

    if (price !== discountedPrice || (orignalPrice && price !== orignalPrice)) {
      return (
        <div className={`${above ? 'flex flex-col-reverse items-start lg:block price-holder' : ''}`}>
          <span {...otherAttributes} className={`price discount ${priceClassName || ''} text-green-color`}>
            {`${currency}${toFixedFloat(finalDiscountedPrice)}`}
          </span>
          {!hideStrikePrice ? (
            <small className="strike-text ml-1 text-red-color">
              {`${currency}${toFixedFloat(finalOrignalPrice)}`}
              &nbsp;
            </small>
          ) : null}
        </div>
      );
    }

    return (
      <div {...otherAttributes} className={`price ${priceClassName || ''} !text-green-color`}>
        {`${currency}${toFixedFloat(finalDiscountedPrice)}`}
      </div>
    );
  }
);
Price.displayName = 'PriceComponent';

export default Price;
