import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import CSSTransition from 'react-transition-group/CSSTransition';

import { useRouter } from 'next/router';
import DynamicModal from '../Modal';

import appContext from '../../Context/appContext';

import notificationTypes from '../../constants/modalNotificationConst';
import DrWeedyMedicalCard from './DrWeedyMedicalCard';

import { THEMEONE, THEMETHREE, THEMETWO } from '../../constants/welcomePopupTypes';
import * as WEBSITES from '../../constants/website';
import storageService from '../../services/storageService';
import GiftConfirmation from './GiftConfirmation';
import AddNewAddress from '../profileAccount/AddNewAddress';
import AddNewCard from '../profileAccount/AddNewCard';
import AddNewAccount from '../profileAccount/AddNewAccount';
import DeleteAddressPopup from './DeleteAddressPopup';
import { isNewOrderDetailsApplicable } from '../../constants/feature';
import PDPLoader from './PDPLoader';
import DeliveryPopupLoader from './DeliveryPopupLoader';
import AgeConfirmationPopup from './AgeConfirmationPopup';
import UpdatedAgeConfirmationPopup from './UpdatedAgeConfirmationPopup';
import ProductDetailsModal from './ProductDetailsModal';
import CategoryVolumePricingPopup from './CategoryVolumePricingPopup';
import DeliveryAddressDetails from '../modalNotification/DeliveryAddressDetails';
import { popNotification, popNotificationOverlap, popSpecifictNotification, popSpecifictNotificationOverlap, pushNotification } from '../../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';

// const AgeConfirmationPopup = dynamic(() => import('./AgeConfirmationPopup'));
// const UpdatedAgeConfirmationPopup = dynamic(() => import('./UpdatedAgeConfirmationPopup'));
const PromoOfferPopup = dynamic(() => import('./PromoOfferPopup'));
const PromoOffer2 = dynamic(() => import('./PromoOffer2'));
const ZippsWelcomePopup = dynamic(() => import('../DTC/zipps/ZippsWelcomePopup'));
const PromoOfferSuccess = dynamic(() => import('../DTC/PromoOfferSuccess'));
const BranchPopup = dynamic(() => import('./BranchPopup'));
const DeliveryAddressPopup = dynamic(() => import('./DeliveryAddressPopup'));
const SubscribeEmailPopup = dynamic(() => import('../DTC/SubscribeEmailPopup'));
const SwitchDeliveryTypePopup = dynamic(() => import('../DTC/CommonSwitchDeliveryTypePopup'));
const SimplePopUp = dynamic(() => import('./SimplePopUp'));

// const DeliveryAddressDetails = dynamic(() => import('../modalNotification/DeliveryAddressDetails'), {
//   loading: () => <DeliveryPopupLoader />
// });
const ShowPromoCode = dynamic(() => import('./ShowPromoCode'));
const ImagePopup = dynamic(() => import('./ImagePopup'));
const Signature = dynamic(() => import('./Signature'));
const RateDriver = dynamic(() => import('./RateDriver'));
const VersionChangeModal = dynamic(() => import('./VersionChangeModal'));
// const ProductDetailsModal = dynamic(() => import('./ProductDetailsModal'), {
//   loading: () => <PDPLoader />
// });
// const ProductDetailsPopup = dynamic(() => import('./ProductDetailsPopup'));
const SearchModalContainer = dynamic(() => import('./SearchModalContainer'));
const DealsPopup = dynamic(() => import('../DTC/DealsPopup'));
const RedirectionPopup = dynamic(() => import('./RedirectionPopup'));
const HypurVerificationPopup = dynamic(() => import('./HypurVerificationPopup'));
const BCCPopup = dynamic(() => import('../DTC/BCCPopup'));
const BulkQuantityPopup = dynamic(() => import('./BulkQuantityPopup'));
// const CategoryVolumePricingPopup = dynamic(() => import('./CategoryVolumePricingPopup'));
const CcpaPopUp = dynamic(() => import('./CcpaPopUp'));
const YoutubeVideoPopup = dynamic(() => import('./YoutubeVideoPopup'));
const StrongHoldPopup = dynamic(() => import('./StrongHoldPopup'));
const FriendsLogin = dynamic(() => import('./FriendsLogin'));
const FriendsCart = dynamic(() => import('./FriendsCart'));
const FriendsFamilyModal = dynamic(() => import('./FriendsFamilyModal'));
const FriendsAndFamilyDiscount = dynamic(() => import('./FriendsAndFamilyDiscount'));
const ScheduleSlot = dynamic(() => import('./ScheduleSlot'));
const ImageCropPopup = dynamic(() => import('./ImageCropPopup'));
const LoginPopup = dynamic(() => import('../DTC/zipps/LoginPopup'));
const LoginModalGD = dynamic(() => import('./LoginModalGD'));
const ZippsOunceDiscountPopup = dynamic(() => import('../DTC/zipps/ZippsOunceDiscountPopup'));
const ViewOffers = dynamic(() => import('./ViewOffersPopup'));
const PriorityDeliveryLearnMore = dynamic(() => import('./PriorityDeliveryLearnMore'));
const RecipientList = dynamic(() => import('./RecipientList'));
const ReferralGiftCardSuccess = dynamic(() => import('./ReferralGiftCardSuccess'));
const GiftPopup = dynamic(() => import('./GiftPopup'));
const EmptyCartPopup = dynamic(() => import('./EmptyCartPopup'));
const ComparePopup = dynamic(() => import('./ComparePopup'));
const OunceCorrectionPopup = dynamic(() => import('../DTC/zipps/CustomOunce/OunceCorrectionPopup'));
const RateReview = dynamic(() => import('./RateReview'));
const OrderCompletePopupOld = dynamic(() => import('./OrderCompletePopupOld'));
const OrderCompletePopup = dynamic(() => import('./OrderCompletePopup'));
const SwitchPaymentPopup = dynamic(() => import('./SwitchPaymentPopup'));
const AmbassadorRewardsPopup = dynamic(() => import('./AmbassadorRewardsPopup'));
const OTPVerification = dynamic(() => import('./OTPVerification'));
const EmailVerification = dynamic(() => import('./EmailVerification'));
const TipPopup = dynamic(() => import('./TipPopup'));
const CouponCodesPopup = dynamic(() => import('./CouponCodesPopup'));
const DebitCardsFieldsPopup = dynamic(() => import('./DebitCardsFieldsPopup'));
const PayOnlinePopup = dynamic(() => import('./PayOnlinePopup'));
const TeslaMissingChancePopup = dynamic(() => import('./TeslaMissingChancePopup'));
const RemoveProductsPopup = dynamic(() => import('./removeProductsPopup'));
const ShareReferralLinkPopup = dynamic(() => import('./ShareReferralLinkPopup'));
const PromoCodePopup = dynamic(() => import('./PromoCodePopup'));
const SimilarProductPopup = dynamic(() => import('./SimilarProductPopup'));
const ConfirmProductRemovalPopup = dynamic(() => import('./ConfirmProductRemovalPopup'));
const FirstTimeUserReward = dynamic(() => import('./FirstTimeUserReward'))
const TabInfoModal = dynamic(() => import("./TabInfo"))
const ModalNotification = ({ notifications, overlap, initialDeliveryAddressDetails = false, domainName }) => {
  const {
    state: {
      // user: { isLoggedIn, urlBeforeProduct },
      // user = {},
      loading
    } = {},
    dispatch
  } = useContext(appContext);
  const { isLoggedIn, urlBeforeProduct } = useSelector(state => state.user)
  const user = useSelector(state => state.user)
  const dispatchRedux = useDispatch()
  const router = useRouter();
  function dismissNotification() {
    if (overlap) {
      dispatchRedux(popNotificationOverlap());
    } else {
      dispatchRedux(popNotification())

    }
  }

  function dismissSpecificNotification(type) {
    dispatchRedux(popSpecifictNotificationOverlap({ type }));
  }
  if (
    (process.browser && notifications.length && notifications[0].type == notificationTypes.ageConfirm) ||
    (process.browser && notifications.length && notifications[0].type == notificationTypes.redirectionPopup) ||
    (process.browser && notifications.length && !loading)
  ) {
    const { type, data } = notifications[0];
    // return (
    //   <DynamicModal>
    //     <Signature data={{ order_id: 123, order_complete_date: "12-12-2021" }} dismiss={dismissNotification} />
    //   </DynamicModal>
    // );
    // return (
    //   <DynamicModal>
    //     <BCCPopup dismiss={dismissNotification} />
    //   </DynamicModal>
    // );

    switch (type) {
      case notificationTypes.tabInfo: return (
        <DynamicModal>
          <TabInfoModal dismiss={dismissNotification} />
        </DynamicModal>
      )
      case notificationTypes.versionModal:
        return (
          <DynamicModal>
            <VersionChangeModal dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.comparePopup:
        return (
          <DynamicModal>
            <ComparePopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.firstTimeUserReward: return (
        <DynamicModal>
          <FirstTimeUserReward dismiss={dismissNotification} />
        </DynamicModal>
      )
      case notificationTypes.searchModal:
        return (
          <DynamicModal>
            <SearchModalContainer dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.verifyOTPPopup:
        return (
          <DynamicModal>
            <OTPVerification dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.verifyEmailPopup:
        return (
          <DynamicModal>
            <EmailVerification dismiss={dismissNotification} />
          </DynamicModal>
        );
      // case notificationTypes.productDetailsPopup: {
      //   return (
      //     <DynamicModal>
      //       <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit>
      //         <ProductDetailsPopup dismiss={dismissNotification} slug={data.slug} page={data.page} />
      //       </CSSTransition>
      //     </DynamicModal>
      //   );
      // }
      case notificationTypes.productDetails: {
        return (
          <DynamicModal>
            {/* <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit> */}
            <ProductDetailsModal
              dismiss={dismissNotification}
              slug={data.slug}
              tab={data.tab}
              page={data.page}
              isBundle={data.isBundle ? data.isBundle : false}
              isDealAvailable={data?.isDealAvailable}
              showTag={data.showTag}
              title={data.title}
              isMostPopularProduct={data.isMostPopularProduct}
              isFeaturedProduct={data?.isFeaturedProduct || false}
              widgetTypeAdded={data?.widgetCategoryProduct}
              widgetType={data?.widgetType}
            />
            {/* </CSSTransition> */}
          </DynamicModal>
        );
      }
      case notificationTypes.rateAndReviewPopup:
        return (
          <DynamicModal>
            <RateReview orderData={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.orderCompletePopup:
        return (
          <DynamicModal>
            {isNewOrderDetailsApplicable && process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' ? (
              <OrderCompletePopup domainName={domainName} data={data} />
            ) : (
              <OrderCompletePopupOld domainName={domainName} data={data} />
            )}
          </DynamicModal>
        );
      case notificationTypes.switchPaymentPopup:
        return (
          <DynamicModal>
            <SwitchPaymentPopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.ageConfirm:
        return (
          <DynamicModal>
            <AgeConfirmationPopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.updatedAgeConfirm:
        return (
          <DynamicModal>
            <UpdatedAgeConfirmationPopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.promoOffer: {
        if (data?.marketingDetails?.theme_id === THEMETHREE) {
          return <ZippsWelcomePopup data={data} dismiss={dismissNotification} />;
        }
        if (data && data.marketingDetails && data.marketingDetails.theme_id === THEMETWO) {
          return <PromoOffer2 data={data} dismiss={dismissNotification} />;
        }
        return <PromoOfferPopup data={data} dismiss={dismissNotification} />;
      }
      case notificationTypes.promoOfferSuccess: {
        return <PromoOfferSuccess data={data} dismiss={dismissNotification} />;
      }
      case notificationTypes.linkOffer:
        return (
          <DynamicModal>
            <BranchPopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.deliverAddressPopup:
        return (
          <DynamicModal>
            <DeliveryAddressPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.dealsPopup:
        return (
          <DynamicModal>
            <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal-mobile" unmountOnExit>
              <DealsPopup dismiss={dismissNotification} data={data} />
            </CSSTransition>
          </DynamicModal>
        );
      case notificationTypes.redirectionPopup:
        return (
          <DynamicModal>
            <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal-mobile" unmountOnExit>
              <RedirectionPopup
                dismiss={dismissNotification}
                data={data}
                initialDeliveryAddressDetails={initialDeliveryAddressDetails}
              />
            </CSSTransition>
          </DynamicModal>
        );
      case notificationTypes.subscribeEmail:
        return (
          <DynamicModal>
            <SubscribeEmailPopup dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.switchDeliveryType:
        return (
          <DynamicModal>
            <SwitchDeliveryTypePopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.simplePopup:
        return (
          <DynamicModal>
            <SimplePopUp data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.simplePopupGiftCard:
        return (
          <DynamicModal>
            <GiftConfirmation
              data={data}
              dismiss={() => {
                storageService.clearRecipients();
                router.push('/gift-cards');
                dismissNotification();
              }}
            />
          </DynamicModal>
        );
      case notificationTypes.deliveryAddressDetail:
        return (
          <DynamicModal>
            {/* <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit> */}
            <DeliveryAddressDetails dismiss={dismissNotification} dispatch={dispatch} user={user} />
            {/* </CSSTransition> */}
          </DynamicModal>
        );
      case notificationTypes.addNewAddress:
        return (
          <DynamicModal>
            <AddNewAddress dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.addNewAccount:
        return (
          <DynamicModal>
            <AddNewAccount dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.addNewCard:
        return (
          <DynamicModal>
            <AddNewCard dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.showPromoCode:
        return (
          <DynamicModal>
            <ShowPromoCode data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.scheduleSlot:
        return (
          <DynamicModal>
            <ScheduleSlot data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.imagePopup:
        return (
          <DynamicModal>
            <ImagePopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.signaturePopup:
        return (
          <DynamicModal>
            <Signature data={data} dismiss={dismissNotification} domainName={domainName} />
          </DynamicModal>
        );
      case notificationTypes.ratingPopup:
        return (
          <DynamicModal>
            <RateDriver data={data} dismiss={dismissNotification} domainName={domainName} />
          </DynamicModal>
        );
      case notificationTypes.pacPopup:
        return (
          <DynamicModal>
            <HypurVerificationPopup data={data} dismiss={dismissNotification} enterPac />
          </DynamicModal>
        );
      case notificationTypes.bccPopup:
        return (
          <DynamicModal>
            <BCCPopup dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.bulkQuantityPopup:
        return (
          <DynamicModal>
            {/* <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit> */}
            <BulkQuantityPopup data={data} dismiss={dismissSpecificNotification} />
            {/* </CSSTransition> */}
          </DynamicModal>
        );
      case notificationTypes.categoryVolumePricing:
        return (
          <DynamicModal>
            <CategoryVolumePricingPopup data={data} dismiss={dismissNotification} page={data.page} />
          </DynamicModal>
        );
      case notificationTypes.ccpaPopUp:
        return (
          <DynamicModal>
            <CcpaPopUp data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.youtubePopup:
        return (
          <DynamicModal>
            <YoutubeVideoPopup data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.friendsCart:
        return (
          <DynamicModal>
            <FriendsCart data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.friendsAndFamilyDiscount:
        return (
          <DynamicModal>
            <FriendsAndFamilyDiscount data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.friendsFamilyModal:
        return (
          <DynamicModal>
            <FriendsFamilyModal data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.friendsLogin:
        return (
          <DynamicModal>
            <FriendsLogin data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );

      case notificationTypes.giftRedeemPopup:
        return (
          <DynamicModal>
            <GiftPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );

      case notificationTypes.strongholdPopUp:
        return (
          <DynamicModal>
            <StrongHoldPopup
              onComplete={token => {
                dismissNotification();
                data.onComplete(token);
              }}
              dismiss={dismissNotification}
            />
          </DynamicModal>
        );

      case notificationTypes.imageCropPopup:
        return (
          <DynamicModal>
            <ImageCropPopup data={data} dismiss={data.isMedicalFormOpened ? dismissSpecificNotification : dismissNotification} />
          </DynamicModal>
        );

      case notificationTypes.loginPopup:
        return (
          <DynamicModal>
            <LoginPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.loginModalGD:
        return (
          <DynamicModal>
            <LoginModalGD dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.ounceDiscountPopup:
        return (
          <DynamicModal>
            <ZippsOunceDiscountPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.viewOffers:
        return (
          <DynamicModal>
            <ViewOffers data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.priorityDeliveryLearnMore:
        return (
          <DynamicModal>
            <PriorityDeliveryLearnMore data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );

      case notificationTypes.recipientList:
        return (
          <DynamicModal>
            <RecipientList data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );

      case notificationTypes.referralGiftCardSuccess:
        return (
          <DynamicModal>
            <ReferralGiftCardSuccess data={data} dismiss={dismissNotification} />
          </DynamicModal>
        );

      case notificationTypes.emptyCartPopup:
        return (
          <DynamicModal>
            <EmptyCartPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );

      case notificationTypes.showDrWeedyMedicalCard:
        return (
          <DynamicModal>
            <DrWeedyMedicalCard dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.ounceCorrectionPopup:
        return (
          <DynamicModal>
            <OunceCorrectionPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.ambassadorRewardsPopup:
        return (
          <DynamicModal>
            <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit>
              <AmbassadorRewardsPopup dismiss={dismissNotification} data={data} />
            </CSSTransition>
          </DynamicModal>
        );
      case notificationTypes.tipPopUp:
        return (
          <DynamicModal>
            <TipPopup dismiss={dismissNotification} />
          </DynamicModal>
        );
      case notificationTypes.couponCodesPopup:
        return (
          <DynamicModal>
            <CouponCodesPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.debitCardsFieldsPopup:
        return (
          <DynamicModal>
            <DebitCardsFieldsPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.payOnlinePopup:
        return (
          <DynamicModal>
            <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit>
              <PayOnlinePopup dismiss={dismissNotification} data={data} />
            </CSSTransition>
          </DynamicModal>
        );
      case notificationTypes.deleteAddressPopup:
        return (
          <DynamicModal>
            <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit>
              <DeleteAddressPopup dismiss={dismissNotification} data={data} />
            </CSSTransition>
          </DynamicModal>
        );
      case notificationTypes.promoCodeApplied:
        return (
          <DynamicModal>
            <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit>
              <PromoCodePopup dismiss={dismissNotification} data={data} />
            </CSSTransition>
          </DynamicModal>
        );
      case notificationTypes.teslaMissingChancePopup:
        return (
          <DynamicModal>
            {/* <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-modal" unmountOnExit> */}
            <TeslaMissingChancePopup dismiss={dismissNotification} data={data} />
            {/* </CSSTransition> */}
          </DynamicModal>
        );
      case notificationTypes.removeProductsPopup:
        return (
          <DynamicModal>
            <RemoveProductsPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );
      case notificationTypes.similarProductPopup:
        return (
          <DynamicModal>
            <SimilarProductPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );

      case notificationTypes.confirmProductRemovalPopup:
        return (
          <DynamicModal>
            <ConfirmProductRemovalPopup dismiss={dismissNotification} data={data} />
          </DynamicModal>
        );

      default:
        return null;
    }
  }
  return null;
};

export default ModalNotification;
