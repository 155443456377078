import React from 'react';
// import appConfig from '../appConfig';
import { useConfigData } from '../Context/ConfigProvider';

function LicenseHolder({ className }) {
  const appConfig = useConfigData();
  return (
    <>
      <a href={appConfig.APP_URL} target="_blank" className={className} rel="noreferrer">
        {appConfig.APP_NAME} &nbsp;
      </a>
      ({appConfig.LICENSE_NUMBER || '-'})
    </>
  );
}

export default LicenseHolder;
