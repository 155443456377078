import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Loader from '../Loader';
import useAPI from '../../NetworkCall/API';

const DeleteAddressPopup = ({ data, dismiss }) => {
  const { addressId } = data;
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAddress } = useAPI();

  const handleProceed = async () => {
    setIsLoading(true);
    await deleteAddress(addressId);
    setIsLoading(false);
    dismiss();
  };

  return (
    <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
      <div className="modal confirm d-block location-popup  animated-popup-wrapper" tabIndex="-1" role="dialog">
        <div className="modal-dialog m-scroll " role="document">
          <div className="modal-content">
            <div className="modal-header no-border align-items-center">
              <h6 className="modal-title text-left bold text-[18px] pl-2">Delete Address</h6>
              <button type="button" className="btn" onClick={dismiss}>
                <i className="icon-close" />
              </button>
            </div>
            <div className="modal-body text-left px-4 pt-0">
              <p className="font-medium mt-2 text-secondGray mb-4">Are you sure you want to remove this saved address?</p>
              <div className="d-flex  button-wrapper mb-3">
                <button
                  type="button"
                  disabled={isLoading}
                  className="btn btn-primary zipps-bold btn-block rouded-button mt-0"
                  onClick={handleProceed}
                >
                  {isLoading ? <Loader /> : 'Remove'}
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  className="btn btn-outline-primary zipps-bold btn-block rouded-button mt-0"
                  onClick={dismiss}
                >
                  Don't remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default DeleteAddressPopup;
