import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import useSegment from '../hooks/segment';
import { PROMO, REFERRAL } from '../constants/couponTypes';
import { getPromoErrorMessage, getBranchSchema } from '../helpers/branchHelper';
import storageService from '../services/storageService';
import notificationTypes from '../constants/modalNotificationConst';
import appContext from '../Context/appContext';
import useAPI from '../NetworkCall/API';
import loadScript from '../helpers/loadScript';

import { APPLY_DISCOUNT } from '../constants/requestUrls';

import { setRollbarWarning } from '../helpers/rollBar';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';
import { setCouponData, setReferrerToken } from '../redux/slices/userSlice';
import { useConfigData } from '../Context/ConfigProvider';

const Branch = ({ initialLocationAddress = false, initialFreightLimit = false, initialPurchaseLimit = false,
  initialUpsellProducts = false, initialDeliveryAddressDetails = false }) => {
  // const {
  //   // state: {
  //   //   user: { isLoggedIn }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const dispatchRedux = useDispatch()
  const { applyCoupon, setReferralLink, getReferralBranchLinkData } = useAPI(initialLocationAddress, initialFreightLimit, initialPurchaseLimit, initialUpsellProducts, initialDeliveryAddressDetails);
  const { trackEvent } = useSegment();
  const appConfig = useConfigData()

  React.useEffect(() => {
    async function loadBranch() {
      if (!window.branch) {
        await loadScript({
          innerHTML:
            '(function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);'
        });
        window.branch.init(appConfig.BRANCH_IO_KEY, async (err, data) => {
          if (data && data.data.trim() !== '' && JSON.parse(data.data)['+clicked_branch_link'] && data.data_parsed) {
            switch (data.data_parsed.type) {
              case PROMO: {
                try {
                  const errorMessage = getPromoErrorMessage(data.data_parsed);
                  const couponDetails = getBranchSchema(data.data_parsed);
                  if (errorMessage) {
                    // Display error
                    toast.error(errorMessage);
                  } else if (!isLoggedIn) {
                    const userInfoLocal = storageService.getUserData() || {};
                    trackEvent('Coupon Entered', {
                      order_id: userInfoLocal.phone,
                      cart_id: userInfoLocal.phone,
                      coupon_id: data.data_parsed.discount_code,
                      mode: 'auto-applied'
                    });
                    // Store coupon details in mobx store
                    // dispatch({ type: 'setCouponData', payload: { couponData: couponDetails } });
                    dispatchRedux(setCouponData({ couponData: couponDetails }))
                    // Store coupon details in local storage
                    storageService.setCouponDetails(couponDetails);
                    storageService.setAutoApplyCoupon();
                    if (couponDetails?.discount_code !== '') {
                      const lastCouponObject = {
                        customer_code: couponDetails?.discount_code,
                        discount: couponDetails?.discount,
                        type: couponDetails.coupon_type,
                        description: ''
                      };

                      storageService.setLastAppliedCoupon(lastCouponObject);
                      // dispatch({ type: 'setLastAppliedCouponData', payload: { lastAppliedCouponData: lastCouponObject } });
                      dispatchRedux(setLastAppliedCoupon({ lastAppliedCouponData: lastCouponObject }))
                    }
                    toast.success('Promo code applied successfully');

                    if (!isZipps) {
                      dispatchRedux(pushNotification({ type: notificationTypes.linkOffer }))
                    }
                  } else {
                    const userInfoLocal = storageService.getUserData() || {};
                    trackEvent('Coupon Entered', {
                      order_id: userInfoLocal.phone,
                      cart_id: userInfoLocal.phone,
                      coupon_id: data.data_parsed.discount_code,
                      mode: 'auto-applied'
                    });
                    await applyCoupon(data.data_parsed.discount_code, {
                      branch: { imgURL: couponDetails.ImgURL, popupMessage: couponDetails.popup_message },
                      showToast: true,
                      cancelRequestUrl: APPLY_DISCOUNT,
                      autoApplied: true
                    });
                  }
                } catch (error) {
                  setRollbarWarning('Branch.js_loadBranch_Promo_catch', error);
                  console.error('Branch.js_loadBranch_Promo_catch', error);
                }
                break;
              }
              case REFERRAL: {
                const {
                  data_parsed: { token }
                } = data;
                try {
                  const userInfoLocal = storageService.getUserData() || {};
                  trackEvent('Coupon Entered', {
                    order_id: userInfoLocal.phone,
                    cart_id: userInfoLocal.phone,
                    coupon_id: token,
                    mode: 'auto-applied'
                  });

                  // dispatch({ type: 'setReferrerToken', payload: { tokenData: token } });
                  dispatchRedux(setReferrerToken({ tokenData: token }))
                  if (!isLoggedIn) {
                    await getReferralBranchLinkData(token);
                  } else {
                    await setReferralLink(token);
                  }
                } catch (error) {
                  setRollbarWarning('Branch.js_loadBranch_Referral_catch', error);
                  console.error('Branch.js_loadBranch_Referral_catch', error);
                }
                break;
              }
              default:
            }
          }
        });
      }
    }
    loadBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  return null;
};

export default Branch;
