import React from 'react';
import InlineLoader from '../InlineLoader';

const InputComponent = ({
  className,
  icon,
  children,
  disabled,
  value,
  type,
  maxLength,
  placeholder,
  onChange,
  error,
  loading,
  loadingText,
  inputClassName,
  visible = true,
  name,
  defaultValue,
  label = 'Label',
  id,
  onBlur,
  onFocus,
  required,
  max,
  hideErrorText = false,
  otherAttributes = {}
}) => {
  if (!visible) {
    return null;
  }
  return (
    <div id={id} className={`addressbox-parent form-group mb-2 ${className} form-floating`}>
      <label className="mini-label">
        {label} {required && '*'}
      </label>
      <div className="i-inner">
        <input
          autoComplete="off"
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          type={type}
          className={`form-control ${inputClassName}`}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          max={max}
          {...otherAttributes}
        />
        {children}
        {loading ? <InlineLoader className="white-bg" text={loadingText} /> : null}
      </div>
      {!hideErrorText && error ? <div className="error-msg">{error}</div> : null}
    </div>
  );
};

export default InputComponent;
