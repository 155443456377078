import React, { useState, useContext } from 'react';
import dynamic from 'next/dynamic';
import isMobile from '../../helpers/isMobile';
import b64toBlob from '../../helpers/base64toBlob';
import Modal from '../Modal';
import notificationTypes from '../../constants/modalNotificationConst';
import imageType from '../../constants/imageType';
import { useIdProof } from '../../hooks/User';

import appContext from '../../Context/appContext';
import useAPI from '../../NetworkCall/API';
import { useDispatch } from 'react-redux';
import { pushNotification, pushOnTopIfNotPresent } from '../../redux/slices/modalSlice';

const CustomCamera = dynamic(() => import('../CustomCamera'));

const IdUpload = ({
  hideSelfie,
  heading = 'Photo ID',
  title = 'We need to See Your ID & Selfie',
  isMedicalFormOpened = false
}) => {
  const [photoIDPreview, setPhotoIDPreview] = useState(null);
  const [selfiePreview, setSelfiePreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [selfieLoader, setSelfieLoader] = useState(false);
  const [photoIdLoader, setPhotoIdLoader] = useState(false);

  const dispatchRedux = useDispatch()
  const { uploadIDStatus } = useAPI();

  const {
    data: { thumbnail_url: thumbnailUrl, selfie_thumbnail_url: selfieThumbnailUrl },
    mutate: mutateIDUPload
  } = useIdProof();

  async function handleFileUpload(file, type, isBase64) {
    try {
      await uploadIDStatus(file, type, progress => setUploadProgress(progress), isBase64);
      mutateIDUPload();
    } finally {
      setSelfieLoader(false);
      setPhotoIdLoader(false);
      setUploadProgress(null);
    }
  }

  function showCropperModal(image, type) {
    dispatchRedux(isMedicalFormOpened ? pushOnTopIfNotPresent({
      type: notificationTypes.imageCropPopup,
      data: {
        image,
        isMedicalFormOpened,
        title: type === imageType.PHOTO_ID ? 'Photo ID' : 'Selfie',
        onCrop: (croppedImage, blob) => {
          if (type === imageType.PHOTO_ID) {
            setPhotoIDPreview(blob);
          } else {
            setSelfiePreview(blob);
          }
          handleFileUpload(croppedImage, type, false);
        }
      }
    }) : pushNotification({
      type: notificationTypes.imageCropPopup,
      data: {
        image,
        isMedicalFormOpened,
        title: type === imageType.PHOTO_ID ? 'Photo ID' : 'Selfie',
        onCrop: (croppedImage, blob) => {
          if (type === imageType.PHOTO_ID) {
            setPhotoIDPreview(blob);
          } else {
            setSelfiePreview(blob);
          }
          handleFileUpload(croppedImage, type, false);
        }
      }
    }))
  }

  function onSelectImage(e, type) {
    const fileObj = [...e.target.files];
    setPhotoIdLoader(true);
    const image = URL.createObjectURL(fileObj[0]);
    showCropperModal(image, type);
  }

  async function onChangeSelfie(imageData) {
    const blob = b64toBlob(imageData);
    const image = URL.createObjectURL(blob);
    setShowCamera(false);
    setSelfieLoader(true);
    // @second param 2 for selfie upload
    showCropperModal(image, imageType.SELFIE);
  }

  function openCamera() {
    setShowCamera(true);
  }

  function closeCamera() {
    setShowCamera(false);
  }

  function onCameraError(error) {
    dispatchRedux(pushNotification({
      type: notificationTypes.simplePopup,
      data: {
        title: 'No camera.',
        text: 'No camera found. Please place your order from your mobile phone.'
      }
    }))
    setShowCamera(false);
  }

  function openImage(imageMode) {
    dispatchRedux(pushNotification({
      // imgURL: photoIDPreview
      imageMode,
      photoIDPreview,
      selfiePreview
    }))
  }

  return (
    <>
      <div className="half-rem-mt" />

      <div className="d-lg-flex justify-content-between checkout-img-upload mb-2">
        {!hideSelfie ? (
          <div className='w-full lg:w-[48%] mb-2 lg:mb-0'>

            <p className="mb-1 text-[12px] text-[#333333] font-[700]">Selfie</p>
            <div style={{ width: "100%" }} className="selfie-block pl-0 rounded-card position-relative">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="d-flex ">
                  <span className="icon-u-selfie mr-2" />
                  {/* <p className="mb-0">Selfie</p> */}
                </div>
                <PhotoUpload
                  photoType={imageType.SELFIE}
                  id="file2"
                  onChange={e => onSelectImage(e, imageType.SELFIE)}
                  disabled={uploadProgress}
                  disableUploadBtn={uploadProgress}
                  capture="user"
                  onClickOpenCamera={openCamera}
                  alreadyUploaded={selfiePreview || selfieThumbnailUrl}
                />
              </div>
              {selfiePreview || selfieThumbnailUrl ? (
                <>
                  <div className="img-container d-flex justify-content-between align-items-center">
                    <img
                      src={selfiePreview || selfieThumbnailUrl}
                      onClick={() => {
                        openImage(imageType.SELFIE);
                      }}
                    />
                    <div className="d-flex align-items-center">
                      {!selfieLoader && (
                        <>
                          <i className="icon-check-circle" />
                          <span className="mx-2">UPLOADED</span>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="img-container d-none">
                  <i className="icon-u-selfie  " />
                </div>
              )}
              {uploadProgress && selfieLoader ? (
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{
                      width: `${uploadProgress}%`
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className='w-full lg:w-[48%]'>
          <p className="mb-1 text-[12px] text-[#333333] font-[700]">Photo ID</p>
          <div style={{ width: "100%" }} className="photo-id-block  rounded-card position-relative">
            <div className=" d-flex justify-content-between align-items-center">
              <div className="d-flex ">
                <span className="icon-u-card mr-2" />
                {/* <p className="mb-0">Photo ID</p> */}
              </div>

              <PhotoUpload
                photoType={imageType.PHOTO_ID}
                id="file"
                name="file"
                onChange={e => onSelectImage(e, imageType.PHOTO_ID)}
                disabled={uploadProgress}
                disableUploadBtn={uploadProgress}
                onClickOpenCamera={openCamera}
                alreadyUploaded={thumbnailUrl || photoIDPreview}
              />
            </div>

            {thumbnailUrl || photoIDPreview ? (
              <>
                <div className="img-container d-flex justify-content-between align-items-center">
                  <img
                    src={photoIDPreview || thumbnailUrl}
                    onClick={() => {
                      openImage(imageType.PHOTO_ID);
                    }}
                  />
                  <div className="d-flex align-items-center">
                    {!photoIdLoader && (
                      <>
                        <i className="icon-check-circle" />
                        <span className="mx-2">UPLOADED</span>
                      </>
                    )}
                  </div>
                </div>
                {uploadProgress && photoIdLoader ? (
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{
                        width: `${uploadProgress}%`
                      }}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div className="img-container d-none">
                <i className="icon-u-card " />
              </div>
            )}
          </div>
        </div>
      </div>
      {!!showCamera && (
        <Modal>
          <CustomCamera closeCamera={closeCamera} onTakePhoto={onChangeSelfie} onCameraError={onCameraError} />
        </Modal>
      )}
    </>
  );
};

export const PhotoUpload = ({
  photoType,
  id,
  name,
  onChange,
  disabled,
  disableUploadBtn,
  capture,
  onClickOpenCamera,
  alreadyUploaded
}) => {
  if (isMobile() || photoType === imageType.PHOTO_ID) {
    return (
      <div className="my-file-input">
        <input type="file" name={name} id={id} onChange={onChange} disabled={disabled} accept="image/*" capture={capture} />
        <label className={disableUploadBtn ? 'disabled' : 'mb-0 btn btn-plus'} htmlFor={id}>
          <i className={`${alreadyUploaded ? 'icon-img-upload' : 'icon-plus'}`} />
        </label>
      </div>
    );
  }
  return (
    <div className="my-file-input">
      <button className="btn p-0 btn-plus" onClick={onClickOpenCamera} type="button" disabled={disableUploadBtn}>
        <i className={`${alreadyUploaded ? 'icon-img-upload' : 'icon-plus'}`} />
      </button>
    </div>
  );
};

export default IdUpload;
