import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import storageService from "../../services/storageService";
import * as WEBSITES from '../../constants/website'
import { COD, STRONGHOLD } from "../../constants/paymentTypes";
import { defaultCardData } from "../../reducers/cart";

export const cartSlice = createSlice({
  name: 'cart',
  initialState: createInitialStore(),
  reducers: {
    setCartData: (state, action) => {

      state.cartData = action.payload
      state.updatingProductsList = []
    },
    setCustomerInfo: (state, action) => {
      // state.recipientDetails = action.payload
      state.customerData = { ...state.customerData, ...action.payload }
      //test re-render

    },
    setBccPopupDismiss: (state, action) => {
      state.bccPopupDismiss = action.payload

    },
    setProductAddedToCart: (state, action) => {
      state.productAddedToCart = action.payload
    },
    setIsAddToCartLoading: (state, action) => {
      state.isAddToCartLoading = action.payload
    },
    setPDPOpenedFromWidget: (state, action) => {
      state.PDPOpenedFromWidget = action.payload

    },
    setCartDetails: (state, action) => {
      state.cartDetails = { ...state.cartDetails, ...action.payload }
    },
    setOutOfStockProducts: (state, action) => {
      state.outOfStockProducts = action.payload
    },
    setCartWidget: (state, action) => {
      state.showCartWidget = action.payload
    },
    setCartWidgetProduct: (state, action) => {
      state.cartWidgetProduct = action.payload
    },
    setCartLoading: (state, action) => {
      state.cartLoading = action.payload
    },
    setViewCartLoading: (state, action) => {
      state.viewCartLoading = action.payload
    },
    setCartCheckoutPossible: (state, action) => {
      state.checkoutPossible = action.payload
    },


    setAddingToCart: (state, action) => {
      state.addToCartLoader = action.payload
    },

    setGuestAddingToCart: (state, action) => {
      state.addToCartGuestLoader = action.payload
    },
    //also add user data here
    setAllCartProductsWidget: (state, action) => {
      state.showAllCartProductsWidget = action.payload
    },
    setUpsellProducts: (state, action) => {
      state.upsellPopularProducts = action.payload
    },
    setUpsellRecommendedProducts: (state, action) => {
      state.upsellRecommendedProducts = action.payload
    },


    setUpdateProductsWidget: (state, action) => {
      state.updateProductWidget = action.payload
    },

    addUpdatingProducts: (state, action) => {

      let updatingProductsList = state.updatingProductsList;
      if (!state.updatingProductsList.includes(action.payload)) {
        updatingProductsList = [...updatingProductsList, action.payload];
      }
      state.updatingProductsList = updatingProductsList
    },

    setAddingNewProductToCart: (state, action) => {
      state.addingNewProductLoader = action.payload
    },

    setCartWidgetLastAddedProduct: (state, action) => {
      state.cartWidgetLastAddedProducts = action.payload
    },
    removeUpdatingProducts: (state, action) => {
      state.updatingProductsList = state.updatingProductsList.filter(productID => productID != action.payload);

    },

    resetCardAndTips: (state) => {
      state.customerData = {
        ...state.customerData,
        paymentType: COD,
        pay_with_card: 0,
        cardData: defaultCardData
      }
      state.cartDetails = { driverTip: 0 }
    },
    setCartCount: (state, action) => {
      const { payload } = action;

      // Ensure state.cart and its nested properties exist
      const existingCartData = state.cart?.cartData || {};

      // Immutably update cart data
      const updatedCartData = {
        ...existingCartData,
        ...(payload.cart_items && {
          cart_items: payload.cart_items,
          cart_item_count: payload.cart_item_count,
        }),
      };

      // Ensure the updated structure is maintained
      state.cart = {
        ...state.cart,
        cartData: updatedCartData,
      };
    },

    setStrongholdInfo: (state, action) => {
      state.customerData = { ...state.customerData, ...action.payload }
    },
    setLedgerGreenCardData: (state, action) => {
      state.ledgerGreenCardData = action.payload

    },
    setLedgerGreenComplianceFee: (state, action) => {
      state.complianceFee = action.payload
    },
    setIsCartRequestFailed: (state, action) => {
      state.isCartRequestFailed = action.payload
    },
    setLastAddedProduct: (state, action) => {
      state.lastAddedProduct = action.payload.product

    },
    setUpdateTemp: (state, action) => {
      const temp = [...state.tempCart]; // Copy the existing tempCart

      // Check if cart_id or product_id exists
      const existingIndex = temp.findIndex(item =>
        item.cart_id === action.payload.cart_id || item.product_id === action.payload.product_id
      );

      if (existingIndex !== -1) {
        // Update existing item if found by cart_id or product_id
        temp[existingIndex] = { ...temp[existingIndex], ...action.payload };
      } else {
        // If not found, add the new payload
        temp.push(action.payload);
      }

      state.tempCart = temp; // Assign the updated array back to state
    },


    removeTempCart: (state, action) => {
      state.tempCart = []

    }
  }
})

export const {
  setUpdateTemp,
  setCartData,
  removeTempCart,

  setCustomerInfo,

  setProductAddedToCart,

  setIsAddToCartLoading,

  setPDPOpenedFromWidget,

  setCartDetails,

  setOutOfStockProducts,

  setCartWidget,

  setCartWidgetProduct,

  setCartLoading,

  setViewCartLoading,

  setCartCheckoutPossible,

  setAddingToCart,

  setAllCartProductsWidget,

  setUpsellProducts,

  setUpsellRecommendedProducts,

  setUpdateProductsWidget,

  addUpdatingProducts,

  setAddingNewProductToCart,

  setCartWidgetLastAddedProduct,

  removeUpdatingProducts,

  resetCardAndTips,

  setCartCount,

  setStrongholdInfo,

  setLedgerGreenCardData,

  setLedgerGreenComplianceFee,

  setIsCartRequestFailed,

  setBccPopupDismiss,

  setLastAddedProduct,
  setGuestAddingToCart
} = cartSlice.actions

export default cartSlice.reducer



function createInitialStore() {
  const userData = storageService.getUserData() || {};
  const userInfo = !isEmpty(userData) ? userData : {};
  const tempCart = []

  const defaultCardData = {
    cardNumber: '',
    cardNumberError: null,
    cardExp: '',
    cardExpError: null,
    cardCVV: '',
    cardCVVError: null,
    cardName: '',
    cardNameError: null,
    zipcode: '',
    zipcodeError: null,
    saveCard: false,
    newCardChecked: false
  };
  const customerData = {
    name: userInfo.name,
    first_name: userInfo.first_name
      ? userInfo.first_name
      : userInfo.name
        ? userInfo.name.substr(0, userInfo.name.indexOf(' ')) === ''
          ? userInfo.name
          : userInfo.name.substr(0, userInfo.name.indexOf(' '))
        : '',
    last_name: userInfo.last_name
      ? userInfo.last_name
      : userInfo.name
        ? userInfo.name.substr(0, userInfo.name.indexOf(' ')) === ''
          ? ''
          : userInfo.name.substr(userInfo.name.indexOf(' ') + 1)
        : '',
    nameError: null,
    first_nameError: null,
    last_nameError: null,
    email: '',
    emailError: null,
    addressError: null,
    apt: '',
    notes: '',
    paymentType: process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ? STRONGHOLD : COD,
    pay_with_card: 0,
    useWalletAmount: true,
    isNewCard: false,
    isLedgerGreenNewCard: false,
    ledgerGreenSelectedCard: {},
    debitSelectedCard: null,
    checkedLGCard: null,
    checkedDebitCard: null,
    cardData: storageService.getDebitCardSession() || defaultCardData,
    useGiftWalletAmount: true,
    complianceFee: null
  };
  const cartDetails = {
    driverTip: 0,
    appliedWallet: 0,
    appliedGiftCardWallet: 0
  };
  const ledgerGreenCardData = storageService.getLedgerGreenCardSession() || defaultCardData;
  const outOfStockProducts = [];
  const productAddedToCart = [];
  const recipientDetails = {};
  const cartData = undefined;
  const showCartWidget = false;
  const showAllCartProductsWidget = false;
  const cartWidgetProduct = '';
  const updatingProductsList = [];
  const updatingOunceType = [];
  const bccPopupDismiss = null;
  const checkoutPossible = true;
  const isAddToCartLoading = false;
  const PDPOpenedFromWidget = false;
  const updateProductWidget = false;
  const cartWidgetLastAddedProducts = [];
  const upsellRecommendedProducts = {};
  const upsellPopularProducts = [];
  const isCartRequestFailed = false;
  const lastAddedProduct = null

  return {
    customerData,

    cartDetails,

    outOfStockProducts,

    cartData,

    updatingProductsList,

    updatingOunceType,

    cartLoading: false,

    viewCartLoading: false,

    addToCartLoader: false,

    showCartWidget,

    showAllCartProductsWidget,

    cartWidgetProduct,

    recipientDetails,

    bccPopupDismiss,

    checkoutPossible,

    ledgerGreenCardData,

    productAddedToCart,

    isAddToCartLoading,

    updateProductWidget,

    cartWidgetLastAddedProducts,

    upsellRecommendedProducts,

    upsellPopularProducts,

    isCartRequestFailed,

    PDPOpenedFromWidget,
    lastAddedProduct,
    tempCart
  };
}
