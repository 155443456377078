
const testOtherDays = date => {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].some(day => date.includes(day));
};

export const shouldPerformActionBasedOnDeliveryDetails = (asapReOpensAt, asapStartTime) => {
    if (!asapStartTime || !asapReOpensAt) return false;

    const currentTime = new Date().toLocaleTimeString('en-US', {
        timeZone: 'America/Los_Angeles',
        hourCycle: 'h23',
    });

    const currentTimeArray = currentTime.split(':');
    const asapTimeArray = asapStartTime.split(':');

    if (currentTimeArray.length !== 3 || asapTimeArray.length !== 3) {
        return false; // Invalid time data
    }

    const diffHours = parseInt(24 - currentTimeArray[0]) + parseInt(asapTimeArray[0]);
    const isForTomorrow = asapReOpensAt.toLowerCase().includes('tomorrow');

    if (
        testOtherDays(asapReOpensAt) ||
        (isForTomorrow && (diffHours > 24 || (diffHours === 24 && parseInt(asapTimeArray[1]) >= parseInt(currentTimeArray[1]))))
    ) {
        return false; // No available slots or time mismatch
    }

    const timeData = {
        hours: parseInt(asapTimeArray[0]) - parseInt(currentTimeArray[0]),
        minutes: parseInt(asapTimeArray[1]) - parseInt(currentTimeArray[1]),
        seconds: parseInt(asapTimeArray[2]) - parseInt(currentTimeArray[2]),
    };

    return Object.keys(timeData).length > 0;
};
