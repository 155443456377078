import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import ProductCounter from './ProductCounter';

import { SCHEDULE } from '../constants/deliveryType';

// import appConfig from '../appConfig';

import ProductSkeleton from './DTC/ProductSkeleton';
import appContext from '../Context/appContext';
import { useSegmentNew } from '../hooks/segment';
import { useDispatch, useSelector } from 'react-redux';
import { setPositionID } from '../redux/slices/otherSlice';
import { useConfigData } from '../Context/ConfigProvider';

const Product = React.memo(({
  title,
  data,
  categorySlug,
  productIndex,
  categoryIndex,
  width50,
  className,
  page,
  openInPopup,
  modalRef,
  source,
  scheduleType,
  isMostPopularProduct,
  isBundle,
  categoryID,
  fromPageType,
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false,
  from,
  isSearchPage,
  hideSlider,
  isSearchedProducts,
  isSimlarProduct,
  setSimlarProductsLoading,
  isSavedProduct = false,
  filtersforSegment,
  widgetTypeAdded = "",
  unified_menu,
  asapStartTime,
  asapEndTime,
  lastSlotCutOff,
  asapEnabled,
  scheduleEnabled,
  fromSpecialCategory

}) => {
  const [productCounterType, setProductCounterType] = useState('count');
  const [showAnimation, setShowAnimation] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [itemInCart, setItemInCart] = useState({ quantity: 0 })
  const [openProductCounter, setOpenProductCounter] = useState(false)
  const [firstTime, setFirstTime] = useState(false)
  const router = useRouter();
  const itemsInCart = useSelector(state => state.cart?.cartData?.cart_items || [])
  useEffect(() => {
    let temp = itemsInCart?.find(item => {
      if (item.product_details_id === data.product_details_id) {
        return true
      }
      return false
    })
    setItemInCart(temp)
  }, [itemsInCart])
  const {
    query: { tab }
  } = router;
  const dispatchRedux = useDispatch()
  const isASAP = tab !== SCHEDULE;

  const autoDismissWidgetTimeout = useRef(null);

  const { trackEvent } = useSegmentNew();

  const showProductPage = useCallback((e) => {
    e.preventDefault();

    const { product_details_id: productDetailsId, bundle_id: bundleID } = data;
    let position = `${productDetailsId}-${categorySlug}-${isASAP ? 'asap' : 'schedule'}${categoryID ? `===cat_${categoryID}` : ''
      }`;
    let url = '/product/[slug]';
    let as = `/product/${data.slug}`;
    if (bundleID) {
      position = `bundle-${bundleID}-${categorySlug}-${isASAP ? 'asap' : 'schedule'}${categoryID ? `===cat_${categoryID}` : ''}`;
      url = '/bundle/[slug]';
      as = `/bundle/${data.slug}`;
    }
    const origin = window.location.origin;

    trackEvent(
      'Product Clicked',
      {
        event: 'Product Clicked',
        payload: {
          product_id: data.bundle_id ? data.bundle_id : data.master_product_id,
          sku: data.bundle_id ? data.bundle_id : data.master_product_id,
          category: data.full_shop_category_name,
          category_frontend: data.category_name,
          name: data.product_name || data.name,
          brand: data?.brand_names?.[0] || '',
          product_attributes: Array.isArray(data.product_attributes_second) ? data.product_attributes_second.join('/') : '',

          variant: `${data.product_weight} ${data.product_unit}`,
          price: data.newDiscountedPrice || data.product_price || data.price,
          original_price: data.price_without_deal,
          coupon: data.coupon,
          strain_type: data.strain_type,
          currency: data?.category_currency === '$' ? 'usd' : data?.category_currency || 'usd',
          position: data.productIndex,
          quantity: 1,
          url: `${origin}${as}`,
          image_url: data.product_image,
          best_seller: isMostPopularProduct,
          recommendation_type: data?.recommendation_type || '',
          widget_category_type: widgetTypeAdded
        },
        // isLoggedIn,
        // email

      })
    // dispatch({ type: 'setPositionID', payload: { position } });
    dispatchRedux(setPositionID({ position }))
    router.push(url, as);
  }, [])
  const startAutoDismissWidget = useCallback(
    () => {
      clearInterval(autoDismissWidgetTimeout.current);
      autoDismissWidgetTimeout.current = setTimeout(() => {
        setProductCounterType('count');
        setOpenProductCounter(false)
      }, 5000);
    },
    []
  )

  const handleProductClick = useCallback(() => {
    event.preventDefault();
    event.stopPropagation();
    if (productCounterType == 'count') {
      setProductCounterType('counter');
      startAutoDismissWidget();
    }

  }, [productCounterType])

  const dismissWidget = useCallback(
    () => {
      setProductCounterType('count');
    },
    []
  )
  const appConfig = useConfigData();

  const { hideProductWeight = false, hideProductProperties = false, SALE_TAG_NAME = 'SALE' } = appConfig;
  return (
    <ProductSkeleton
      title={title}
      page={page}
      data={data}
      categorySlug={categorySlug}
      width50={width50}
      className={className}
      SALE_TAG_NAME={SALE_TAG_NAME}
      showProductPage={showProductPage}
      hideProductProperties={hideProductProperties}
      hideProductWeight={hideProductWeight}
      showAnimation={showAnimation}
      addingToCart={addingToCart}
      // itemInCart={itemInCart}
      handleProductClick={handleProductClick}
      productCounterType={productCounterType}
      openInPopup={openInPopup}
      modalRef={modalRef}
      productIndex={productIndex}
      categoryIndex={categoryIndex}
      isBundle={isBundle}
      scheduleType={scheduleType}
      categoryID={categoryID}
      isMostPopularProduct={isMostPopularProduct}
      from={from}
      fromPage={fromPageType}
      isSearchPage={isSearchPage}
      hideSlider={hideSlider}
      isSearchedProducts={isSearchedProducts}
      isSimlarProduct={isSimlarProduct}
      setSimlarProductsLoading={setSimlarProductsLoading}
      isSavedProduct={isSavedProduct}
      filtersforSegment={filtersforSegment}
      unified_menu={unified_menu}
      asapStartTime={asapStartTime}
      asapEndTime={asapEndTime}
      lastSlotCutOff={lastSlotCutOff}
      asapEnabled={asapEnabled}
      scheduleEnabled={scheduleEnabled}
      initialLocationAddress={initialLocationAddress}
      initialFreightLimit={initialFreightLimit}
      initialPurchaseLimit={initialPurchaseLimit}
      initialUpsellProducts={initialUpsellProducts}
      initialDeliveryAddressDetails={initialDeliveryAddressDetails}
      fromSpecialCategory={fromSpecialCategory}
      setShowLoader={setShowLoader}
      showLoader={showLoader}
      setOpenProductCounter={(val) => { setOpenProductCounter(val); !itemInCart && val && setFirstTime(true) }}
    >
      {openProductCounter || itemInCart?.quantity > 0 ? <ProductCounter
        page={page}
        className="version-2"
        actionMade={startAutoDismissWidget}
        dismiss={dismissWidget}
        type={productCounterType}
        product={data}
        // itemInCart={itemInCart}
        // quantity={quantity}
        // onDeleteItem={dismissWidget}
        scheduleType={scheduleType}
        isMostPopularProduct={isMostPopularProduct}
        initialLocationAddress={initialLocationAddress}
        initialFreightLimit={initialFreightLimit}
        initialPurchaseLimit={initialPurchaseLimit}
        initialUpsellProducts={initialUpsellProducts}
        initialDeliveryAddressDetails={initialDeliveryAddressDetails}
        from={from}
        fromPage={fromPageType}
        filtersforSegment={filtersforSegment}
        setShowLoader={setShowLoader}
        showLoader={showLoader}
        setFirstTime={setFirstTime}
        firstTime={firstTime}
        setOpenProductCounter={setOpenProductCounter}
      /> : null}
    </ProductSkeleton>
  );
});

export default Product;
