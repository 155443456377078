import Link from 'next/link';
import React, { useContext, useEffect, useState } from 'react';
import appContext from '../../Context/appContext';
import SearchBar from '../SearchBar';
import useAPI from '../../NetworkCall/API';
import { toast } from 'react-toastify';
// import appConfig from '../../appConfig';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewAddressForProfile } from '../../redux/slices/otherSlice';
import { useConfigData } from '../../Context/ConfigProvider';

const CONSTANT_ADDRESS_TYPES = [
  {
    addressType: 1,
    addressTypeName: 'Home'
  },
  {
    addressType: 2,
    addressTypeName: 'Office'
  },
  {
    addressType: 3,
    addressTypeName: ''
  }
];

const UpdateAddress = ({ addressDetails, is_new = false }) => {
  const { address_id, is_default, address_type, address_type_name } = addressDetails;
  const [showInput, setShowInput] = useState(false)
  const appConfig = useConfigData();
  const [addressTypeObj, setaddressTypeObj] = useState({
    addressType: address_type,
    addressTypeName: address_type_name
  });
  // const {
  //   state: { newAddressForProfile },
  //   dispatch
  // } = useContext(appContext);
  const newAddressForProfile = useSelector(state => state.other.newAddressForProfile)
  const dispatchredux = useDispatch()

  const { checkZipCodeStatus } = useAPI();

  useEffect(() => {
    // updating the address type in state
    if (newAddressForProfile) {
      // dispatch({
      //   type: 'updateNewAddressForProfile',
      //   payload: {
      //     ...newAddressForProfile,
      //     address_type: addressTypeObj.addressType,
      //     address_type_name: addressTypeObj.addressTypeName
      //   }
      // });
      dispatchredux(updateNewAddressForProfile({
        ...newAddressForProfile,
        address_type: addressTypeObj.addressType,
        address_type_name: addressTypeObj.addressTypeName
      }))
    }
  }, [addressTypeObj]);

  useEffect(() => {
    // if other type showing the input with default value
    if (addressTypeObj.addressType == CONSTANT_ADDRESS_TYPES[2].addressType) {
      setShowInput(true)
    }
  }, [addressTypeObj])

  const placeInputChange = async placeObj => {
    const { address, latitude, longitude, postcode, city, state, placeId, country } = placeObj;
    const data = await checkZipCodeStatus(placeObj);
    if (!data.validZipcode && !data.error) {
      toast.error(`It looks like your location is outside of ${appConfig.APP_NAME}’s service area.`);
      // dispatch({
      //   type: 'updateNewAddressForProfile',
      //   payload: {}
      // });
      dispatchredux(updateNewAddressForProfile({}))
      return false;
    }
    // dispatch({
    //   type: 'updateNewAddressForProfile',
    //   payload: {
    //     address_id,
    //     is_default,
    //     address_type,
    //     address_type_name,
    //     address,
    //     address_1: address,
    //     latitude,
    //     longitude,
    //     postcode,
    //     city,
    //     state,
    //     place_id: placeId,
    //     country
    //   }
    // });
    dispatchredux(updateNewAddressForProfile({
      address_id,
      is_default,
      address_type,
      address_type_name,
      address,
      address_1: address,
      latitude,
      longitude,
      postcode,
      city,
      state,
      place_id: placeId,
      country
    }))
  };

  const onClickHome = () => {
    setaddressTypeObj(CONSTANT_ADDRESS_TYPES[0]);
    setShowInput(false)
  };

  const onClickOffice = () => {
    setaddressTypeObj(CONSTANT_ADDRESS_TYPES[1]);
    setShowInput(false)
  };

  const onClickOther = () => {
    setaddressTypeObj(CONSTANT_ADDRESS_TYPES[2]);
    setShowInput(true)
  };

  const addressTypeInputChange = e => {
    setaddressTypeObj({
      addressType: CONSTANT_ADDRESS_TYPES[2].addressType,
      addressTypeName: e.target.value
    });
  };
  return (
    <div className="update-address">
      {/* <label className="mb-2">Set Delivery Address</label> */}
      <SearchBar onPlacesChanged={placeInputChange} value={newAddressForProfile?.address} changeInput={is_new ? true : false} />
      <div className="one-rem-mt map-open">
        <Link
          href={{
            pathname: '/locationselect',
            query: { fromProfile: true, address_id, is_default, address_type, address_type_name, is_new }
          }}
          className="btn btn-link d-inline-block one-em-font text-left"
        >
          <a className="pin-location">
            <span className="m-icon icon-map-center" />
            &nbsp; Or pin your location &nbsp;
          </a>
        </Link>
      </div>
      {/*  <---- Will need this on enhancement --->
       <label className="mt-3">Address type</label> 
      <div className="address-type d-flex mt-1">
        <button
          type="button"
          onClick={onClickHome}
          className={`type ${addressTypeObj.addressType == CONSTANT_ADDRESS_TYPES[0].addressType && 'active'}`}
        >
          <span className="icon-address-home"></span>
          Home
        </button>
        <button
          type="button"
          onClick={onClickOffice}
          className={`type ${addressTypeObj.addressType == CONSTANT_ADDRESS_TYPES[1].addressType && 'active'}`}
        >
          <span className="icon-address-office"></span>
          Office
        </button>
        <button
          type="button"
          onClick={onClickOther}
          className={`type ${addressTypeObj.addressType == CONSTANT_ADDRESS_TYPES[2].addressType && 'active'}`}
        >
          <span className="icon-address-marker"></span>
          Other
        </button>
      </div> 
      {showInput &&
        <form className="mt-2" autoComplete="off">
          <label className="mt-3">Address name</label>
          <input
            type="text"
            placeholder="Enter address name"
            className="form-control"
            maxLength={20}
            value={addressTypeObj.addressType == CONSTANT_ADDRESS_TYPES[2].addressType ? addressTypeObj.addressTypeName : ''}
            onChange={addressTypeInputChange}
          />
        </form>
      }  */}
    </div>
  );
};

export default UpdateAddress;