// import { Player } from '@lottiefiles/react-lottie-player';
import React, { useContext, useEffect, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { HYPUR, KIND_TAP, STRONGHOLD } from '../../constants/paymentTypes';
import appContext from '../../Context/appContext';
import { useDeliveryDetails } from '../../hooks/app';
import useAPI from '../../NetworkCall/API';
import LinkBankAccount from '../../public/static/lotties/LinkBankAccount.json';
import FullscreenLoader from '../FullScreenLoader';
import notificationTypes from '../../constants/modalNotificationConst';
import loadScript from '../../helpers/loadScript';
import { useStrongholdList } from '../../hooks/User';
// import appConfig from '../../appConfig';
import { useHyperURL } from '../../hooks/cart';
import nameValidationWithError from '../../helpers/nameValidationWithError';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotificationOverlap } from '../../redux/slices/modalSlice';
import LottieRender from '../LottieRender';
import { useConfigData } from '../../Context/ConfigProvider';

// const { Player } = dynamic(() => import('@lottiefiles/react-lottie-player'), {ssr: false});

const AddNewAccount = ({ dismiss }) => {
  // const {
  //   state: {
  //     // user: {
  //     //   deliveryDetails: { address, postcode, state, city },
  //     //   userInfo: { phone }
  //     // },
  //     // stronholdTokenData,
  //     // cart: {
  //     //   cartData: {
  //     //     kindtapTransactionFee,
  //     //     strongHoldTransactionFee,
  //     //     strongHoldEnabled,
  //     //     createKindtap,
  //     //     hypur_blocked: hypurBlocked
  //     //   } = {},
  //     //   cartData: { name: savedName, first_name: savedFirstName, last_name: savedLastName, createStrongHold } = {},
  //     //   customerData: { first_name, last_name, email }
  //     // }
  //   }
  // } = useContext(appContext);
  const appConfig = useConfigData();
  const stronholdTokenData = useSelector(state => state.other.stronholdTokenData)
  const dispatchRedux = useDispatch();

  const {
    deliveryDetails: { address, postcode, state, city },
    userInfo: { phone }
  } = useSelector(state => state.user);

  const {
    cartData: {
      kindtapTransactionFee,
      strongHoldTransactionFee,
      strongHoldEnabled,
      createKindtap,
      hypur_blocked: hypurBlocked
    } = {},
    cartData: { name: savedName, first_name: savedFirstName, last_name: savedLastName, createStrongHold } = {},
    customerData: { first_name, last_name, email } = {}
  } = useSelector(state => state.cart) || {};

  const [selectedAccount, setSelectedAccount] = useState(STRONGHOLD);
  const { createStrongholdToken, createKindTapAccount, createStrongholdAccount } = useAPI();
  const { data: strongHoldData, isLoading: isAccountsLoading, mutate, kindTapPayments: kindTapData } = useStrongholdList();
  const [customerData, setCustomerData] = useState({
    fname: '',
    lname: '',
    fnameError: null,
    lnameError: null
  });

  const {
    data: { locationId }
  } = useDeliveryDetails();
  const { data: hypurRedirectUrl } = useHyperURL();
  const [isLoading, setIsLoading] = useState(false);

  const firstSavedName =
    savedFirstName ||
    (savedName
      ? savedName.substr(0, savedName.indexOf(' ')) === ''
        ? savedName
        : savedName.substr(0, savedName.indexOf(' '))
      : null);
  const lastSavedName =
    savedLastName ||
    (savedName ? (savedName.substr(0, savedName.indexOf(' ')) === '' ? '' : savedName.substr(savedName.indexOf(' ') + 1)) : null);

  const firstName = firstSavedName || first_name;
  const lastName = lastSavedName || last_name;

  const isNewStrongHoldAccount = (!firstName || !lastName) && createStrongHold ? true : false;
  const dismissModal = () => {
    dismiss();
  };

  useEffect(() => {
    async function loadStronghold() {
      if (!window.Stronghold) {
        await loadScript({
          url: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js'
        });
        await loadScript({
          url: 'https://api.strongholdpay.com/v2/js'
        });
      }
    }
    loadStronghold();
    const createToken = async () => {
      await createStrongholdToken();
    };
    createToken();
  }, []);

  // Add StrongHold Account
  async function addStrongholdPayment() {
    const { fname, lname } = customerData;
    if (isNewStrongHoldAccount) {
      const first_nameError = nameValidationWithError(fname, 'First Name');
      if (first_nameError) {
        toast.error(first_nameError);
        setCustomerData({ ...customerData, fnameError: first_nameError });
        return false;
      }
      const last_nameError = nameValidationWithError(lname, 'Last Name');
      if (last_nameError) {
        toast.error(last_nameError);
        setCustomerData({ ...customerData, lnameError: last_nameError });
        return false;
      }
    }
    setIsLoading(true);
    const strongholdPay = window.Stronghold.Pay({
      publishableKey:
        locationId == 2
          ? appConfig.STRONGHOLD_PUBLISHABLE_KEY_SF
          : appConfig.STRONGHOLD_PUBLISHABLE_KEY_LA,
      // environment: 'sandbox',// Or live
      environment: process.env.NEXT_PUBLIC_ENV != 'production' ? 'sandbox' : 'live',
      integrationId: appConfig.STRONGHOLD_INTEGRATION_KEY
    });

    const { token } = stronholdTokenData;
    if (token) {
      strongholdPay.addPaymentSource(token, {
        onReady: () => { },
        onSuccess: paymentSource => {
          mutate();
          setIsLoading(false);
          // makeCharge(paymentSource)
        },
        onExit: () => {
          setIsLoading(false);
        },
        onError: err => {
          setIsLoading(false);
          const {
            error: { message = '' }
          } = err;
          toast.error(message);
        }
      });
    } else if ((firstName && lastName) || (fname && lname)) {
      const token = await createStrongholdAccount({ firstName: firstName || fname, lastName: lastName || lname });
      strongholdPay.addPaymentSource(token, {
        onReady: () => { },
        onSuccess: paymentSource => {
          mutate();
          setIsLoading(false);
        },
        onExit: () => {
          setIsLoading(false);
        },
        onError: err => {
          setIsLoading(false);
          const {
            error: { message = '' }
          } = err;
          toast.error(message);
        }
      });
    }
    setIsLoading(false);
    dismiss();
  }

  // Add KindTap Account
  const addNewKindTapAccount = async () => {
    setIsLoading(true);
    if (createKindtap) {
      window.KindTap.signup(
        {
          companyName: appConfig.APP_NAME,
          email,
          firstName: first_name,
          lastName: last_name,
          phone,
          address1: address,
          zip: postcode,
          state,
          city
        },
        async function (success) {
          await createKindTapAccount(success.token);
          mutate();
          setIsLoading(false);
        },
        function (error) {
          setIsLoading(false);
          toast.error(error.error);
        },
        function () {
          setIsLoading(false);
        }
      );
    }
    setIsLoading(false);
    dismiss();
  };

  const addNewHypurAccount = async () => {
    setIsLoading(true);
    if (hypurRedirectUrl && !hypurBlocked) {
      window.open(hypurRedirectUrl, '_blank');
    }
    setIsLoading(false);
    dismiss();
  };

  const handleApply = async () => {
    switch (selectedAccount) {
      case HYPUR:
        addNewHypurAccount();
        break;
      case STRONGHOLD:
        addStrongholdPayment();
        break;
      case KIND_TAP:
        addNewKindTapAccount();
        break;
      default:
        addStrongholdPayment();
        break;
    }
  };

  const handleCheckbox = e => {
    switch (e.target.id) {
      case `${HYPUR}-checkbox`:
        setSelectedAccount(HYPUR);
        break;
      case `${STRONGHOLD}-checkbox`:
        setSelectedAccount(STRONGHOLD);
        break;
      case `${KIND_TAP}-checkbox`:
        setSelectedAccount(KIND_TAP);
        break;
      default:
        setSelectedAccount(STRONGHOLD);
        break;
    }
  };

  function setCustomerInfo(e) {
    setCustomerData({ ...customerData, [e.target.name]: e.target.value, [`${e.target.name}Error`]: null });
  }

  const { fname, lname, fnameError, lnameError } = customerData;

  return (
    <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
      <div className="modal d-block add-address-popup animated-popup-wrapper link-bank-account" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h6 className="modal-title">Link a Bank Account</h6>
              <button type="button" className="btn" onClick={dismissModal}>
                <i className="icon-close" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="logo-container">
                {/* <Player className="lottie-address" autoplay loop src={LinkBankAccount} /> */}
                <LottieRender path='../../static/lotties/LinkBankAccount.json' className="lottie-address" />
              </div>
              <div className="payment-holder">
                <div className="input-parent mb-3 form-group" onChange={handleCheckbox}>
                  {strongHoldEnabled && !!stronholdTokenData ? (
                    <div className="custom-checkbox">
                      <input type="checkbox" id={`${STRONGHOLD}-checkbox`} checked={selectedAccount === STRONGHOLD} />
                      <label className="align-top m-0 stronghold" htmlFor={`${STRONGHOLD}-checkbox`}>
                        <div>
                          <span>
                            Pay with
                            <img className="payment-logo" src="/static/images/stronghold-payment.png" alt="Stronghold" />
                          </span>
                        </div>
                        <div className="">
                          <button
                            type="button"
                            className="btn info-alert has-box-shadow"
                            onClick={() => {
                              dispatchRedux(pushNotificationOverlap({ type: notificationTypes.payOnlinePopup, data: {} }))
                            }}
                          >
                            <span className="icon-info-italic info-alert-icon grey-color" />
                          </button>
                        </div>
                        {strongHoldTransactionFee == 0 || strongHoldTransactionFee == null || !strongHoldTransactionFee ? (
                          <div className="bottom-text">
                            <em className="md-grey-color ml-2">No fee</em>
                          </div>
                        ) : null}
                      </label>
                    </div>
                  ) : null}
                  {createKindtap ? (
                    <div className="custom-checkbox">
                      <input type="checkbox" id={`${KIND_TAP}-checkbox`} checked={selectedAccount === KIND_TAP} />
                      <label className="align-top m-0 kindtap" htmlFor={`${KIND_TAP}-checkbox`}>
                        <div className="">
                          <span>
                            Pay with
                            <img className="payment-logo kindtap" src="/static/images/kindtap-payment.png" alt="Kindtap" />
                          </span>
                        </div>
                        <div className="">
                          <button
                            type="button"
                            className="btn info-alert has-box-shadow"
                            onClick={() => {
                              dispatchRedux(pushNotificationOverlap({ type: notificationTypes.payOnlinePopup, data: {} }))
                            }}
                          >
                            <span className="icon-info-italic info-alert-icon grey-color" />
                          </button>
                        </div>
                        <div className="bottom-text kindtap">
                          <em className="md-grey-color ml-2">
                            {`Buy Now Pay Later${kindtapTransactionFee == 0 || kindtapTransactionFee == null || !kindtapTransactionFee
                              ? ', No fee'
                              : ''
                              }`}
                          </em>
                        </div>
                      </label>
                    </div>
                  ) : null}
                  {hypurBlocked == 0 ? (
                    <div className="custom-checkbox">
                      <input type="checkbox" id={`${HYPUR}-checkbox`} checked={selectedAccount === HYPUR} />
                      <label className="align-top m-0" htmlFor={`${HYPUR}-checkbox`}>
                        Pay with
                        <img className="payment-logo" src="/static/images/hypur-payment.png" alt="Hypur" />
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
              {selectedAccount == STRONGHOLD && (!firstName || !lastName) && createStrongHold ? (
                <div id="customer-card-details" className="card-details">
                  <div className="inner">
                    <div className="relative">
                      <div className="addressbox-parent form-group row">
                        <div className="col">
                          <div className="form-group ">
                            <label className="mini-label d-block">First Name</label>
                            <input
                              id="customer-first-name"
                              icon="icon-user"
                              value={fname}
                              name="fname"
                              type="text"
                              maxLength="25"
                              placeholder="First Name"
                              onChange={setCustomerInfo}
                              className={`form-control one-em-font ${fnameError ? `err-validate` : ''}`}
                            />
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group ">
                            <label className="mini-label d-block">Last Name</label>
                            <input
                              id="customer-last-name"
                              icon="icon-user"
                              value={lname}
                              name="lname"
                              type="text"
                              maxLength="25"
                              placeholder="Last Name"
                              onChange={setCustomerInfo}
                              className={`form-control one-em-font ${lnameError ? `err-validate` : ''}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="text-center mb-4">
              <button type="button" className="btn btn-primary" onClick={handleApply}>
                Proceed
              </button>
            </div>
          </div>
        </div>
        {isLoading && <FullscreenLoader hasTransparentBg />}
      </div>
    </CSSTransition>
  );
};

export default AddNewAccount;
