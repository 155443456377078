import { useContext, useEffect, useRef } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import cookie from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import axioss from 'axios';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';

import axios from '../utils/ajax';
import StorageService from '../services/storageService';
import notificationTypes from '../constants/modalNotificationConst';
import categoriesHelper from '../helpers/categoriesHelper';

import { displayCouponApplyMessage, getBranchSchema, getCouponSchema } from '../helpers/branchHelper';
import getFirstAvailableTimeSlot from '../helpers/getFirstAvailableTimeSlot';

import { ASAP, ASAP_AND_SCHEDULE, SCHEDULE } from '../constants/deliveryType';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_ASAP_PRIORITY, SCHEDULE_TYPE_FUTURE } from '../constants/scheduleType';
import * as ScheduleType from '../constants/scheduleWindow';
import { COD, CARD, HYPUR, POS, LEDGERGREEN } from '../constants/paymentTypes';
import imageType from '../constants/imageType';
import { isAddToCartRecommendationApplication, isRecommendedCartUpsellApplicable } from '../constants/feature';

import appContext from '../Context/appContext';

import * as COOKIE_VARIABLES from '../constants/cookieVariables';

import useSegment from '../hooks/segment';
import {
  useFullAddressHistory,
  useCardList,
  useIdProof,
  useLoyaltyDiscount,
  useWeedyStatus,
  useReferralGiftcard
} from '../hooks/User';
import { usePurchaseLimit, useWalletWithPurchaseRewards } from '../hooks/cart';
import { useDeliveryDetails, useFreightLimit, useZipCodeStatus } from '../hooks/app';
import {
  DEFAULT_GIFTCARD_ZIPCODE,
  DEFAULT_ZIPCODE,
  DEFAULT_GIFTCARD_LOCATION,
  DEFAULT_ZONE_ID,
  DEFAULT_DATETIME
} from '../constants/default';
import getCartType, { checkType } from '../helpers/getCartType';
import { APPLY_DISCOUNT, GET_CART, GET_LOGOUT_INVOICE, CREATE_STRONGHOLD } from '../constants/requestUrls';
import hasValidDeliveryType from '../helpers/hasValidDeliveryType';
import CustomOunceTypes from '../constants/customOunceConsts';
import { setRollbarWarning } from '../helpers/rollBar';
import * as WEBSITES from '../constants/website';
import getFilterQueryString from '../helpers/getFilterQueryString';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import { wishlistToast } from '../helpers/wishlistToast';
import getProductIdsBeforeAddedTocart from '../helpers/getProductIds';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdatingProducts, removeTempCart, setAddingNewProductToCart, setAddingToCart, setCartData, setCartDetails, setCartLoading, setCustomerInfo, setIsAddToCartLoading, setIsCartRequestFailed, setLastAddedProduct, setLedgerGreenComplianceFee, setOutOfStockProducts, setProductAddedToCart, setStrongholdInfo, setUpsellProducts, setUpsellRecommendedProducts, setViewCartLoading } from '../redux/slices/cartSlice';
import { login, saveLocation, setCartSwitched, setCouponData, setFetchProductOnLoginFromPopupInfo, setLastAppliedCouponData, confirmEmailOTPSlice, setUserInfo, updateLocation, requestOTPSlice } from '../redux/slices/userSlice';
import { setFavouriteItems, setFavouriteItemsCount, setFavouriteItemsLoading, setFilteredItemsCount, setIsFavouriteItemAddedOrRemoved, setIsSavedForLaterItemAddedOrRemoved, setSaveForLaterItems, setSaveForLaterItemsCount, setSaveForLaterItemsLoading } from '../redux/slices/wishlistSlice';
import { pushNotification, pushNotificationOverlap, popNotificationAll } from '../redux/slices/modalSlice';
import { setSeoData, updateUserID } from '../redux/slices/otherSlice';
import { setRewardUnlocked } from '../redux/slices/rewardSlice';
import { calculateTotalPriceWithTax } from '../helpers/calculateTotalWithTax';
import { useConfigData } from '../Context/ConfigProvider';

const API_URL = process.env.NEXT_PUBLIC_SECURE_EC2_URL;
const WAREHOUSE_EC2_URL = process.env.NEXT_PUBLIC_SECURE_WAREHOUSE_EC2_URL;
const ACS_API_URL = process.env.NEXT_PUBLIC_ACS_API_URL;

let saveLaterDataRef = [];
const useAPI = (
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialDeliveryAddressDetails = false
) => {
  const { state, dispatch } = useContext(appContext);
  const userStatePre = useSelector(state => state.user)
  const otherStatePre = useSelector(state => state.other)
  const cartStatePre = useSelector(state => state.cart) || {}
  const modalStatePre = useSelector(state => state.modal)
  const wishlistStatePre = useSelector(state => state.wishlist)
  const rewardStatePre = useSelector(state => state.rewards)
  const { mutate: mutateAddressHistory } = useFullAddressHistory();
  const { data: deliveryDetailsData } = useDeliveryDetails(initialDeliveryAddressDetails);

  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);
  const dispatchRedux = useDispatch();

  const appConfig = useConfigData()

  const { mutate: mutateWalletWithPurchaseRewards } = useWalletWithPurchaseRewards();

  const validZipcodeRef = useRef(validZipcode);

  const userState = useRef()
  const rewardState = useRef()
  const otherState = useRef()
  const cartState = useRef()
  const modalState = useRef()
  const wishlistState = useRef()

  useEffect(() => {
    rewardState.current = rewardStatePre
  }, [rewardStatePre])

  useEffect(() => {
    userState.current = userStatePre
  }, [userStatePre])

  useEffect(() => {
    otherState.current = otherStatePre
  }, [otherStatePre])

  useEffect(() => {
    cartState.current = cartStatePre
  }, [cartStatePre])

  useEffect(() => {
    modalState.current = modalStatePre
  }, [modalStatePre])

  useEffect(() => {
    wishlistState.current = wishlistStatePre
  }, [wishlistStatePre])

  useEffect(() => {
    validZipcodeRef.current = validZipcode;
  }, [validZipcode]);

  const newstate = useRef(state);

  useEffect(() => {
    newstate.current = state;
  }, [state]);

  const deliveryDetailsRef = useRef(deliveryDetailsData);

  useEffect(() => {
    deliveryDetailsRef.current = deliveryDetailsData;
  }, [deliveryDetailsData]);

  const { identifyEvent, trackEvent } = useSegment();

  const { data: cardList, mutate: mutateCardList } = useCardList();

  const { mutate: mutateFreightLimit } = useFreightLimit(initialFreightLimit);

  const { mutate: mutatePurchaseLimit } = usePurchaseLimit(initialPurchaseLimit);

  const { mutate: mutateWeedyStatus } = useWeedyStatus();
  const { mutate: mutateReferralGiftcard } = useReferralGiftcard();

  const { mutate: mutatePrescription } = useIdProof();

  const { isLoyal } = useLoyaltyDiscount();

  const router = useRouter();

  const routerRef = useRef(router);

  useEffect(() => {
    routerRef.current = router;
  }, [router]);

  const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

  async function getProductDetailsBySlug(slug, postcode, scheduleType) {

    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    try {

      const response = await axios({
        url: `${API_URL}/products/${slug}/${postcode}?schedule_type=${scheduleType}`,
        method: 'GET',
        headers: { 'x-origin': hostURL, zc: DEFAULT_ZIPCODE }
      });

      const {
        data: { categories }
      } = response;

      delete categories?.recommended_products;
      return categories;
    } catch (error) {
      return null;
    }
  }

  async function getRecommendedProductsBySlug(slug) {
    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    try {
      const response = await axios({
        url: `${API_URL}/products/getRecommendedProducts/${slug}`,
        method: 'GET',
        headers: { 'x-origin': hostURL }
      });

      const {
        data: { data }
      } = response;

      return data;
    } catch (error) {
      return null;
    }
  }

  async function getUpsellPopularProducts(slug = '', productId, existingCartProductIds = []) {
    // const {
    //   user: {
    //     deliveryDetails: { zoneId: zone_id }
    //   }
    // } = newstate.current;
    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    const { deliveryDetails: { zoneId: zone_id } } = userState.current
    // dispatch({ type: 'setIsAddToCartLoading', payload: false });
    dispatchRedux(setIsAddToCartLoading(false))

    const zoneId = zone_id || cookie.get(COOKIE_VARIABLES.zoneId) || DEFAULT_ZONE_ID;

    if (!isAddToCartRecommendationApplication || !isRecommendedCartUpsellApplicable) {
      // dispatch({ type: 'setProductAddedToCart', payload: {} });
      dispatchRedux(setProductAddedToCart({}))
      // dispatch({ type: 'setIsAddToCartLoading', payload: false });
      dispatchRedux(setIsAddToCartLoading(false))
      return;
    }

    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/cart-view/${zoneId}/${slug}`,
        method: 'GET',
        headers: { 'x-origin': hostURL, 'product-ids': productId, 'cartProduct-Ids': existingCartProductIds.toString() }
      });

      const upsellProductsAdded = get(response, 'data.data', {});
      // dispatch({ type: 'setIsAddToCartLoading', payload: false });
      dispatchRedux(setIsAddToCartLoading(false))
      // dispatch({ type: 'setProductAddedToCart', payload: upsellProductsAdded });
      dispatchRedux(setProductAddedToCart(upsellProductsAdded))
      return upsellProductsAdded;
    } catch (error) {
      // dispatch({ type: 'setIsAddToCartLoading', payload: false });
      dispatchRedux(setIsAddToCartLoading(false))
      // dispatch({ type: 'setProductAddedToCart', payload: [] });
      dispatchRedux(setProductAddedToCart([]))
      return null;
    }
  }

  async function getUpsellProducts() {
    // const {
    //   user: {
    //     deliveryDetails: { zoneId: zone_id }
    //   },
    //   cart: { cartData: { cart_items: cartItems = [] } = {}, updateProductWidget }
    // } = newstate.current;

    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    const { deliveryDetails: { zoneId: zone_id } } = userState.current
    const { cartData: { cart_items: cartItems = [] } = {}, updateProductWidget } = cartState.current

    const zoneId = zone_id || cookie.get(COOKIE_VARIABLES.zoneId) || DEFAULT_ZONE_ID;

    const productFinalIds = cartItems?.length ? getProductIdsBeforeAddedTocart(cartItems) : [];

    if (!productFinalIds?.length || updateProductWidget || !isRecommendedCartUpsellApplicable) {
      return null;
    }

    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/cart-upsell/popular/${zoneId}`,
        method: 'GET',
        headers: { 'x-origin': hostURL, 'product-ids': productFinalIds.toString() },
        hideError: true
      });

      const upsellProductsAdded = get(response, 'data.categories', []);
      // dispatch({ type: 'setUpsellProducts', payload: upsellProductsAdded });
      dispatchRedux(setUpsellProducts(upsellProductsAdded))
      return upsellProductsAdded;
    } catch (error) {
      // dispatch({ type: 'setUpsellProducts', payload: [] });
      dispatchRedux(setUpsellProducts([]))
      return null;
    }
  }

  async function getUpsellRecommendedProducts() {
    // const {
    //   user: {
    //     deliveryDetails: { zoneId: zone_id }
    //   },
    //   cart: { cartData: { cart_items: cartItems = [] } = {}, updateProductWidget }
    // } = newstate.current;

    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    const { deliveryDetails: { zoneId: zone_id } } = userState.current
    const { cartData: { cart_items: cartItems = [] } = {}, updateProductWidget } = cartState.current

    const zoneId = zone_id || cookie.get(COOKIE_VARIABLES.zoneId) || DEFAULT_ZONE_ID;

    const productFinalIds = cartItems?.length ? getProductIdsBeforeAddedTocart(cartItems) : [];

    if (!productFinalIds?.length || updateProductWidget || !isRecommendedCartUpsellApplicable) {
      return null;
    }

    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/cart-view/${zoneId}`,
        method: 'GET',
        headers: { 'x-origin': hostURL, 'product-ids': productFinalIds.toString() },
        hideError: true
      });

      const upsellProductsAdded = get(response, 'data.data', {});
      // dispatch({ type: 'setUpsellRecommendedProducts', payload: upsellProductsAdded });
      dispatchRedux(setUpsellRecommendedProducts(upsellProductsAdded))
      return upsellProductsAdded;
    } catch (error) {
      // dispatch({ type: 'setUpsellRecommendedProducts', payload: {} });
      dispatchRedux(setUpsellRecommendedProducts({}))
      return null;
    }
  }

  async function getRecommendedProductsByIdASAP(slug, bundleId, grassdoorBundle) {
    // const {
    //   user: {
    //     deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
    //   }
    // } = newstate.current;
    const { deliveryDetails: { zoneId = DEFAULT_ZONE_ID } } = userState.current

    const productSlugOrId = grassdoorBundle && bundleId ? bundleId : slug;

    if (!productSlugOrId) {
      return;
    }

    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/product-details/popular/asap/${zoneId}/${productSlugOrId}`,
        method: 'GET',
        headers: { 'x-origin': hostURL, 'bundle-Id': grassdoorBundle && bundleId ? bundleId : '' },
        hideError: true
      });

      const {
        data: { data }
      } = response;

      data.slug = slug;

      dispatch({ type: 'setProductRecommendationsAsap', payload: data });

      return data;
    } catch (error) {
      dispatch({ type: 'setProductRecommendationsAsap', payload: {} });
      return null;
    }
  }

  async function getRecommendedProductsByIdSchedule(slug, bundleId, grassdoorBundle) {
    // const {
    //   user: {
    //     deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
    //   }
    // } = newstate.current;
    const { deliveryDetails: { zoneId = DEFAULT_ZONE_ID } } = userState.current

    const productSlugOrId = grassdoorBundle && bundleId ? bundleId : slug;

    if (!productSlugOrId) {
      return;
    }

    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/product-details/popular/schedule/${zoneId}/${productSlugOrId}`,
        method: 'GET',
        headers: { 'x-origin': hostURL, 'bundle-Id': grassdoorBundle && bundleId ? bundleId : '' },
        hideError: true
      });

      const {
        data: { data }
      } = response;

      data.slug = slug;

      dispatch({ type: 'setProductRecommendationsSchedule', payload: data });

      return data;
    } catch (error) {
      dispatch({ type: 'setProductRecommendationsSchedule', payload: {} });
      return null;
    }
  }

  async function getSimilarProducts({ slug, hideError = false }) {
    // const {
    //   user: {
    //     deliveryDetails: { zoneId = DEFAULT_ZONE_ID }
    //   }
    // } = newstate.current;
    const hostURL = window && window.location.origin.includes('localhost:3000')
      ? process.env.NEXT_PUBLIC_FALLBACK_API_URL
      : window.location.origin.replace(/^https?:\/\//, '')
    const { deliveryDetails: { zoneId = DEFAULT_ZONE_ID } } = userState.current
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_PRODUCT_RECOMMENDATIONS_URL}/recommend/out-of-stock/schedule/${zoneId}/${slug}`,
        method: 'GET',
        headers: { 'x-origin': hostURL },
        hideError: hideError
      });

      let {
        data: { data }
      } = response;
      data.slug = slug;
      return data;
    } catch (error) {
      return null;
    }
  }

  async function requestOTP(via, paramCountryCode, phoneNumber) {
    const response = await axios({
      url: `${API_URL}/authenticate/login`,
      method: 'POST',
      data: {
        telephone: phoneNumber,
        country_code: paramCountryCode,
        user_type: 1,
        via
      },
      params: {
        excludeLocationToken: true
      }
    });
    const smsMessage = get(response, 'data.data.sms_disclaimer_message');
    const smsCheckbox = get(response, 'data.data.sms_disclaimer_checkbox');
    const isFriends = get(response, 'data.data.is_prime', false);
    StorageService.setFnfLocal(isFriends);

    const tcpaRewardAmount = get(response, 'data.data.tcpa_reward_amount');
    dispatch({ type: 'requestOTP', payload: { smsMessage, smsCheckbox, tcpaRewardAmount } });
    dispatchRedux(requestOTPSlice({ smsMessage, smsCheckbox, tcpaRewardAmount }))
  }

  async function confirmOtp(
    paramCountryCode,
    phoneNumber,
    otp,
    additionalDetails,
    smsCheckbox,
    marketingUser,
    isGiftCardRedeemState,
    loginFromPopup
  ) {
    // const {
    //   user: {
    //     tokenData,
    //     userInfo: { phone, country: countryCode }
    //   },
    //   cart: { cartData: { cart_items: cartItems = [] } = {} }
    // } = newstate.current;

    const {
      tokenData,
      userInfo: { phone, country: countryCode }
    } = userState.current
    const { cartData: { cart_items: cartItems = [] } = {} } = cartState.current

    const {
      query: { friends }
    } = routerRef.current;

    const email = StorageService.getEmail();

    let clientId = '';

    try {
      if (window.analytics && typeof ga === 'function') {
        // eslint-disable-next-line func-names
        ga(function (tracker) {
          clientId = tracker.get('clientId');
        });
      }
    } catch (error) {
      setRollbarWarning('API.js_confirmOtp_catch', error);
      console.error('API.js_confirmOtp_catch', error);
    }

    let requestData = {
      telephone: phoneNumber,
      phone_verification_otp: otp,
      country_code: paramCountryCode,
      user_type: 1,
      sms_disclaimer_checked: smsCheckbox,
      email,
      clientId,
      marketing_user: marketingUser,
      referrer_token: tokenData
    };

    if (additionalDetails) {
      requestData = { ...requestData, ...additionalDetails };
    }

    const isFriends = StorageService.getFnfLocal();

    if (friends && !isFriends && !isLoyal && !isCheckoutOnly) {
      requestData.is_prime = true;
    }

    const response = await axios({
      url: `${API_URL}/authenticate/verify`,
      method: 'POST',
      data: requestData,
      hideError: true,
      excludeLocationToken: true
    });

    const data = get(response, 'data.data', {});
    if (data.token) {
      StorageService.setUserData({
        name: data.name,
        phone: data.telephone,
        country: countryCode,
        user_id: data.user_id,
        first_name: data.first_name
          ? data.first_name
          : data.name.substr(0, data.name.indexOf(' ')) === ''
            ? data.name
            : data.name.substr(0, data.name.indexOf(' ')),
        last_name: data.last_name
          ? data.last_name
          : data.name.substr(0, data.name.indexOf(' ')) === ''
            ? ''
            : data.name.substr(data.name.indexOf(' ') + 1)
      });

      const { display_message: { order_message: message, order_title: title } = {}, discount_value: discountValue = '$0' } = data;

      if (title && message && !isZipps) {
        dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { title, text: message, discountValue } }))
      }

      if (loginFromPopup) {
        // dispatch({ type: 'setFetchProductOnLoginFromPopupInfo', payload: { shouldFetchProductOnLoginFromPopup: true } });
        dispatchRedux(setFetchProductOnLoginFromPopupInfo({ shouldFetchProductOnLoginFromPopup: true }))
      }

      if (process.env.NEXT_PUBLIC_APP === WEBSITES.GOVANA || isCheckoutOnly) {
        cookie.set(COOKIE_VARIABLES.token, data.token, { expires: 300, sameSite: 'none', secure: true });
      } else {
        cookie.set(COOKIE_VARIABLES.token, data.token, { expires: 300, sameSite: 'lax' });
      }

      StorageService.setAmbassadorLocalStorage(!!data.is_ambassador);
      StorageService.clearEmail();

      // Make isLoggedIn TRUE after all localstorage are set
      const userInfo = {
        name: data.name,
        phone: data.telephone,
        country: countryCode,
        user_id: data.user_id,
        first_name: data.first_name
          ? data.first_name
          : data.name.substr(0, data.name.indexOf(' ')) === ''
            ? data.name
            : data.name.substr(0, data.name.indexOf(' ')),
        last_name: data.last_name
          ? data.last_name
          : data.name.substr(0, data.name.indexOf(' ')) === ''
            ? ''
            : data.name.substr(data.name.indexOf(' ') + 1),
        isGiftCardRedeemState
      };

      if (data.customer_email) {
        userInfo.email = data.email;
        // dispatch({ type: 'setCustomerInfo', payload: { email: userInfo.email } });
        dispatchRedux(setCustomerInfo({ email: userInfo.email }))
      }

      if (['/cart', '/checkout'].includes(router.pathname)) {
        if (cartItems && cartItems.length) {
          trackEvent(`cart-page-logging-in-item-present`);
        } else {
          trackEvent(`cart-page-logging-in-no-cart-item`);
        }
      }
      // if (window.analytics) {
      const obj = { id: data.user_id, phone: phoneNumber };
      if (data.name) {
        obj.name = data.name;
        obj.firstName = data.first_name
          ? data.first_name
          : data.name.substr(0, data.name.indexOf(' ')) === ''
            ? data.name
            : data.name.substr(0, data.name.indexOf(' '));
        obj.lastName = data.last_name
          ? data.last_name
          : data.name.substr(0, data.name.indexOf(' ')) === ''
            ? ''
            : data.name.substr(data.name.indexOf(' ') + 1);
      }

      if (email) {
        obj.email = email;
      }

      if (data.customer_email) {
        obj.email = data.customer_email;
      }

      identifyEvent(data.user_id, obj);
      // }
      if (data.coupon_code) {
        const couponData = {
          discount_code: data.coupon_code
        };

        // dispatch({ type: 'setCouponData', payload: { couponData, isNewUserWithPromotion: true } });
        dispatchRedux(setCouponData({ couponData, isNewUserWithPromotion: true }))

        StorageService.setUserWithPermission(true);
        StorageService.setCouponDetails(couponData);
        dispatchRedux(popNotificationAll());
      }

      const userTempData = JSON.parse(StorageService.getItem('userTempData')) || {
        firstName: '',
        lastName: ''
      };

      if (isCheckoutOnly) {
        dispatchRedux(popNotificationAll());
      }

      if (data.error_message) {
        toast.error(data.error_message);
        dispatchRedux(popNotificationAll());
      }

      // dispatch({ type: 'login', payload: { isLoggedIn: true, userInfo } });
      dispatchRedux(login({ isLoggedIn: true, userInfo }))

      // dispatch({
      //   type: 'setCustomerInfo',
      //   payload: {
      //     name: userInfo.name,
      //     first_name: userInfo?.first_name || userTempData?.firstName || '',
      //     last_name: userInfo?.last_name || userTempData?.lastName || ''
      //   }
      // });
      dispatchRedux(setCustomerInfo({
        name: userInfo.name,
        first_name: userInfo?.first_name || userTempData?.firstName || '',
        last_name: userInfo?.last_name || userTempData?.lastName || ''
      }))

      fetchCashBacks();

      if (friends && !isLoyal && data.is_prime && data.sign_up) {
        StorageService.setFnfLocal(true);
        dispatchRedux(pushNotification({ type: notificationTypes.friendsLogin }))
      }

      if (friends && !data.is_prime) {
        dispatchRedux(pushNotification({ type: notificationTypes.friendsAndFamilyDiscount }))
      }

      if (friends && data.is_prime && !data.sign_up) {
        router.push('/');
      }

      mutateFreightLimit();
      mutateCardList();
      // mutateUpsellProducts();
      mutatePurchaseLimit();
      return !!data.wallet_credited
    }
  }

  async function FnFOptIn() {
    try {
      const response = await axios({
        url: `${API_URL}/customers/prime/sign_up`,
        method: 'POST',
        data: {}
      });
      if (response.data.success) {
        StorageService.setFnfLocal(true);
        dispatchRedux(pushNotification({ type: notificationTypes.friendsLogin }));
      }
    } catch (error) {
      setRollbarWarning('API.js_FnFOptIn_catch', error);
      console.error('API.js_FnFOptIn_catch', error);
      // return { data: null, error };
    }
  }

  async function CCPAsubmit(data) {
    try {
      const config = {
        warehouse: 'warehouse'
      };
      const response = await axios({
        url: `${WAREHOUSE_EC2_URL}/ccpa-compliance-requests`,
        method: 'POST',
        data,
        ...config
      });
      const CCPAtoken = get(response, 'data.data.request_id', '');
      const emailOTPDisplay = get(response, 'data.data.email_otp', '');
      const phoneOTPDisplay = get(response, 'data.data.phone_number_otp', '');
      dispatch({
        type: 'CCPAsubmit',
        payload: { CCPAtoken, emailOTPDisplay, phoneOTPDisplay }
      });
      return { data: response };
    } catch (error) {
      setRollbarWarning('API.js_CCPAsubmit_catch', error);
      console.error('API.js_CCPAsubmit_catch', error);
      return { data: error };
    }
  }

  async function CCPAconfirmOTP(data) {
    try {
      const config = {
        warehouse: 'warehouse'
      };
      const response = await axios({
        url: `${WAREHOUSE_EC2_URL}/ccpa-compliance-requests/verify`,
        method: 'POST',
        data,
        ...config
      });
      const CCPAtoken = get(response, 'data.data.request_id', '');
      dispatch({
        type: 'CCPAconfirmOTP',
        payload: { CCPAtoken }
      });
      return response;
    } catch (error) {
      setRollbarWarning('API.js_CCPAconfirmOTP_catch', error);
      console.error('API.js_CCPAconfirmOTP_catch', error);
      // return { data: null, error };
    }
  }

  async function uploadPrescription(file, type, onChangeProgress, hideSuccess) {
    const url = `${API_URL}/customers/upload_prescription/${type}`;

    const formData = new FormData();
    formData.append(type === imageType.MEDICAL_CARD ? 'medicalCard' : 'prescription', file);

    formData.append('isBase64Image', false);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        const uploadProgress = (progressEvent.loaded * 100) / progressEvent.total;
        if (onChangeProgress) {
          onChangeProgress(uploadProgress);
        }
      }
    };

    await axios({
      url,
      method: 'POST',
      ...config,
      data: formData,
      hideSuccess
    });
  }

  async function deletePrescription(type, hideSuccess) {
    try {
      await axios({
        url: `${API_URL}/customers/upload_prescription/${type}`,
        method: 'DELETE',
        hideSuccess
      });
    } catch (error) {
      setRollbarWarning('API.js_deletePrescription_catch', error);
      console.error('API.js_deletePrescription_catch', error);
    }
  }

  async function uploadIDStatus(file, type, onChangeProgress, isBase64Image = false, hideSuccess) {
    try {
      const url = `${API_URL}/customers/upload_image/${type}`;
      const formData = new FormData();
      formData.append(type === imageType.PHOTO_ID || type === imageType.LICENCE ? 'userIdPhoto' : 'userSelfie', file);

      formData.append('isBase64Image', isBase64Image);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const uploadProgress = (progressEvent.loaded * 100) / progressEvent.total;
          if (onChangeProgress) {
            onChangeProgress(uploadProgress);
          }
        }
      };
      await axios({
        url,
        method: 'POST',
        ...config,
        data: formData,
        hideSuccess
      });
      // post(url, formData, config);
    } catch (error) {
      setRollbarWarning('API.js_uploadIDStatus_catch', error);
      console.error('API.js_uploadIDStatus_catch', error.response);
      throw error;
    }
  }

  async function checkZipCodeStatus(data) {
    const {
      postcode: paramPostcode = DEFAULT_ZIPCODE,
      latitude: paramLatitude,
      longitude: paramLongitude,
      initialLocationAddress
    } = data
    try {
      const {
        query: { giftcard }
      } = routerRef.current;
      const config = {
        giftcard: !!giftcard
      };
      // const {
      //   user: { shouldFetchProduct,
      //     deliveryDetails: { zoneId: existing_zone_id },
      //     deliveryDetails }
      // } = newstate.current;

      const {
        shouldFetchProduct,
        deliveryDetails: { zoneId: existing_zone_id },
        deliveryDetails
      } = userState.current

      if (initialLocationAddress && !giftcard && !shouldFetchProduct) {
        dispatch({ type: 'setLocationData', payload: initialLocationAddress });

        const isValidZipcode = paramPostcode == DEFAULT_ZIPCODE ? false : initialLocationAddress?.is_available || false;
        const locationId = initialLocationAddress?.location_id || false;
        const zoneName = initialLocationAddress?.zone_name || '';
        const zoneId = paramPostcode == DEFAULT_ZIPCODE ? 0 : initialLocationAddress?.zone_id || DEFAULT_ZONE_ID;
        // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, zoneId: zoneId } });
        dispatchRedux(saveLocation({ ...deliveryDetails, zoneId: zoneId }))
        if (process.browser) {
          StorageService.setZoneLocalStorage(zoneName);
          if (zoneId !== existing_zone_id) {
            StorageService.setDeliveryDetails({
              ...deliveryDetails,
              zoneId: zoneId
            });
            cookie.set(COOKIE_VARIABLES.zoneId, zoneId);
          }
        }

        return { validZipcode: isValidZipcode, locationId, zoneId };
      }

      const response = await axios({
        url: `${API_URL}/address/${paramPostcode}?lat=${paramLatitude}&lng=${paramLongitude}`,
        method: 'GET',
        ...config
      });

      if (response) {
        dispatch({ type: 'setLocationData', payload: response.data });

        const isValidZipcode = paramPostcode == DEFAULT_ZIPCODE ? false : response?.data?.is_available || false;
        const locationId = response?.data?.location_id || false;
        const zoneName = response?.data?.zone_name || '';
        const zoneId = paramPostcode == DEFAULT_ZIPCODE ? 0 : response?.data?.zone_id || DEFAULT_ZONE_ID;
        // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, zoneId: zoneId } });

        dispatchRedux(saveLocation({ ...(!initialLocationAddress ? data : initialLocationAddress), zoneId: zoneId }))
        if (process.browser) {
          StorageService.setZoneLocalStorage(zoneName);
          if (zoneId !== existing_zone_id) {
            StorageService.setDeliveryDetails({
              ...deliveryDetails,
              zoneId: zoneId
            });
            cookie.set(COOKIE_VARIABLES.zoneId, zoneId);
          }
        }

        return { validZipcode: isValidZipcode, locationId, zoneId };
      }

      return { validZipcode: false, locationId: false, zoneId: DEFAULT_ZONE_ID, error: true };
    } catch (error) {
      return { validZipcode: false, locationId: false, zoneId: DEFAULT_ZONE_ID, error: true };
    }
  }

  async function getPlaceId({ lat, lng }) {
    try {
      const response = await axioss.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}`
      );
      if (response) {
        const locationObj = get(response, 'data.results', []);
        const placesId = locationObj && locationObj.length && locationObj[0].place_id;
        return { placeId: placesId };
      }
    } catch (error) {
      return { placeId: false };
    }
    return { placeId: false };
  }

  async function requestEmailOTP(email) {
    StorageService.setEmail(email);
    await axios({
      url: `${API_URL}/customers/email_otp`,
      method: 'POST',
      data: {
        email
      },
      params: {
        excludeLocationToken: true
      }
    });
  }

  async function confirmEmailOTP(email, otp) {
    const response = await axios({
      url: `${API_URL}/customers/email_verify`,
      method: 'POST',
      data: {
        email,
        verfication_code: otp
      },
      params: {
        excludeLocationToken: true
      }
    });
    const promoCode = get(response, 'data.data.promo_code', '');
    dispatchRedux(pushNotification({ type: notificationTypes.showPromoCode, data: promoCode }))
    dispatch({ type: 'confirmEmailOTP', payload: { isEmailVerified: true } });
    dispatchRedux(confirmEmailOTPSlice({ isEmailVerified: true }))
  }

  async function getCart(mutatedData, revalidate = true, { createDynamicOunce, dynamicOunceDeliveryType, localCart } = {}) {
    dispatchRedux(setCartLoading(true))

    const {
      addingNewProductLoader,
      customerData: { paymentType, useWalletAmount, useGiftWalletAmount } = {},
      cartDetails: { driverTip, appliedWallet, appliedGiftCardWallet },
      cartData,
      cartData: { cart_items: cartItems = [] } = {},
      complianceFee,
      tempCart
    } = cartState.current

    const { rewardUnlocked } = rewardState.current
    const {
      isLoggedIn,
      deliveryDetails: {
        postcode = DEFAULT_ZIPCODE,
        deliveryTypeSelected,
        deliveryDate,
        deliveryTime,
        latitude,
        longitude,
        deliveryData,
        deliveryDateTime
      },
      deliveryDetails,
      couponData: { user_coupon: userCoupon, id: couponID } } = userState.current

    const { deliveryDays, asapEnabled = true, priorityDeliveryConfigId } = deliveryDetailsRef.current;

    const recipientDetails = StorageService.getRecipientData();

    const {
      query: { deliveryType, giftcard }
    } = routerRef.current;

    if (deliveryType && ['/cart', '/checkout'].includes(window.location.pathname) && !window.routing) {
      router.replace('/checkout', '/checkout', { shallow: true });
    }

    const selectedDelivery = deliveryTypeSelected;
    // const selectedDelivery = dynamicOunceDeliveryType !== undefined ? dynamicOunceDeliveryType : deliveryTypeSelected;

    let scheduleTime;
    let zoneWiseScheduleWindowsId = 0;
    let scheduleWindowsId = 0;

    if (
      selectedDelivery === SCHEDULE_TYPE_FUTURE &&
      deliveryDays &&
      deliveryDays.length &&
      deliveryDate >= 0 &&
      deliveryDays[deliveryDate] &&
      deliveryDays[deliveryDate].deliveryWindows &&
      deliveryDays[deliveryDate].deliveryWindows.length
    ) {
      const dateTimeObj = find(deliveryDays[deliveryDate].deliveryWindows, { startTime: deliveryTime });
      scheduleTime = dateTimeObj ? dateTimeObj.dateTime : deliveryDays[deliveryDate].deliveryWindows[0].dateTime;
      if (deliveryDateTime !== DEFAULT_DATETIME) {
        zoneWiseScheduleWindowsId = dateTimeObj ? dateTimeObj.zone_wise_schedule_windows_id : 0;
        scheduleWindowsId = dateTimeObj ? dateTimeObj.schedule_windows_id : 0;
      }
    } else if (selectedDelivery !== SCHEDULE_TYPE_ASAP) {
      scheduleTime = getFirstAvailableTimeSlot(-1, true, false);
      zoneWiseScheduleWindowsId = 0;
      scheduleWindowsId = 0;
    }

    let maxAmount = 0;
    if (cartData?.maxWalletAmount) {
      maxAmount =
        parseFloat(cartData.maxWalletAmount) > parseFloat(cartData.walletAmount)
          ? parseFloat(cartData.walletAmount).toFixed(2)
          : parseFloat(cartData.maxWalletAmount).toFixed(2);
    }

    const params = {
      payment_mode: paymentType,
      apply_wallet:
        useWalletAmount && appliedWallet ? appliedWallet : useWalletAmount ? maxAmount || +useWalletAmount : +useWalletAmount,
      driverTip,
      schedule_time: scheduleTime,
      user_coupon: userCoupon,
      cart_type: checkType(getCartType([...cartItems, ...tempCart])),
      latitude: giftcard ? DEFAULT_GIFTCARD_LOCATION.lat : latitude,
      longitude: giftcard ? DEFAULT_GIFTCARD_LOCATION.lng : longitude,
      // create_dynamic_ounce: isZipps ? Number(createDynamicOunce) : undefined,
      apply_gift_card_wallet:
        useGiftWalletAmount && appliedGiftCardWallet
          ? appliedGiftCardWallet
          : useGiftWalletAmount
            ? cartData?.giftCardWalletAmount
              ? cartData.giftCardWalletAmount
              : +useGiftWalletAmount
            : +useGiftWalletAmount,
      isFirstHit: appliedWallet || !useWalletAmount || appliedGiftCardWallet || !useGiftWalletAmount ? 0 : 1
    };
    if (deliveryTypeSelected === SCHEDULE_TYPE_ASAP_PRIORITY) {
      params.priority_delivery_config_id = priorityDeliveryConfigId;
    }
    if (zoneWiseScheduleWindowsId) {
      params.zone_wise_schedule_windows_id = zoneWiseScheduleWindowsId;
    }
    if (scheduleWindowsId) {
      params.schedule_windows_id = scheduleWindowsId;
    }
    if (complianceFee && paymentType == LEDGERGREEN) {
      params.lg_fees = complianceFee;
    }
    if (giftcard && recipientDetails) {
      params.gc_amount = recipientDetails?.amount;
    }

    let finalCartItems = [];
    let finalAsapCount;

    if (!isCheckoutOnly && !validZipcodeRef.current && !giftcard) {
      // dispatch({ type: 'setCartLoading', payload: false });
      dispatchRedux(setCartLoading(false))
      return;
    }

    if (isLoggedIn) {
      if (revalidate) {
        try {
          const config = {
            giftcard: !!giftcard
          };
          const response = await axios({
            baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL,
            url: `/carts/${giftcard ? `gift_card_checkout/${DEFAULT_GIFTCARD_ZIPCODE}` : `${postcode}/${couponID || ''}`}`,
            params,
            method: 'GET',
            requestUrl: GET_CART,
            cancelRequestUrl: GET_CART,
            ...config
          });
          if (rewardUnlocked !== response.data.walletWithPurchaseAmount) {
            if (Number(response.data.walletWithPurchaseAmount) > rewardUnlocked) {
              if (response.data.walletWithPurchaseAmount > 0 && (response.data.walletWithPurchaseAmount !== JSON.parse(StorageService.getItem("creditUnlocked")))) {
                toast.success("$" + response.data.walletWithPurchaseAmount + " Credit Unlocked")
                StorageService.setItem("creditUnlocked", response.data.walletWithPurchaseAmount)
              }


            }
            dispatchRedux(setRewardUnlocked(response.data.walletWithPurchaseAmount))

          }

          if (addingNewProductLoader) {
            // dispatch({ type: 'setAddingNewProductToCart', payload: false });
            dispatchRedux(setAddingNewProductToCart(false))
          }

          // dispatch({ type: 'setCartData', payload: response.data });

          const to = setTimeout(() => {
            dispatchRedux(removeTempCart())
            clearTimeout(to)
          }, 500)
          const { EXCISE_TAX_RATE = 0, LOCAL_CANNABIS_TAX_RATE = 0, SALES_TAX_RATE = 0 } = appConfig
          dispatchRedux(setCartData({ ...response.data, totalWithCannabisTax: calculateTotalPriceWithTax(response.data?.cart_items || [], { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE }) }))
          // dispatch({ type: 'setViewCartLoading', payload: false });
          dispatchRedux(setViewCartLoading(false))
          if (response.data) {
            const {
              name,
              // allow_card_save: allowCardSave,
              isCardBlocked,
              hypur_blocked: hypurBlocked,
              posEnabled,
              asap_count: asapCount,
              cart_items: cartItemsServer = [],
              walletAmount
            } = response.data;

            // dispatch({ type: 'setCustomerInfo', payload: { allowCardSave } }); allowCardSave used from /get_cards api

            finalCartItems = cartItemsServer;
            finalAsapCount = asapCount;

            if (paymentType == CARD && isCardBlocked == 1) {
              // dispatch({ type: 'setCustomerInfo', payload: { paymentType: COD } });
              dispatchRedux(setCustomerInfo({ paymentType: COD }))
            }

            if (name) {
              const first_name = name
                ? name.substr(0, name.indexOf(' ')) === ''
                  ? name
                  : name.substr(0, name.indexOf(' '))
                : null;
              const last_name = name
                ? name.substr(0, name.indexOf(' ')) === ''
                  ? ''
                  : name.substr(name.indexOf(' ') + 1)
                : null;
              // dispatch({ type: 'setCustomerInfo', payload: { firstName: first_name, lastName: last_name } });
              dispatchRedux(setCustomerInfo({ firstName: first_name, lastName: last_name }))
            }

            if (paymentType == HYPUR && hypurBlocked) {
              // dispatch({ type: 'setCustomerInfo', payload: { paymentType: COD } });
              dispatchRedux(setCustomerInfo({ paymentType: COD }))
            }

            if (paymentType == POS && !posEnabled) {
              // dispatch({ type: 'setCustomerInfo', payload: { paymentType: COD } });
              dispatchRedux(setCustomerInfo({ paymentType: COD }))
            }

            if (!walletAmount) {
              // dispatch({ type: 'setCustomerInfo', payload: { useWalletAmount: false } });
              dispatchRedux(setCustomerInfo({ useWalletAmount: false }))
            }
          }

          const { exceededConcentrates, exceededNonConcentrates } = response.data;
          // dispatch({ type: 'setIsCartRequestFailed', payload: false });
          dispatchRedux(setIsCartRequestFailed(false))
        } catch (error) {
          // Incase if axios request is cancelled
          // Cancelled request returns empty error object
          if (!isEmpty(error)) {
            setRollbarWarning('API.js_getCart_catch', error);
            console.error('API.js_getCart_catch', { error });
            // dispatch({ type: 'setIsCartRequestFailed', payload: true });
            dispatchRedux(setIsCartRequestFailed(true))
            throw error;
          }
        }
      }
      if (!isEmpty(mutatedData)) {
        // dispatch({ type: 'setCartData', payload: { ...mutatedData, mutatedData: true } });
        dispatchRedux(setCartData({ ...mutatedData, mutatedData: true }))
      }
    } else {
      const localCartItems = localCart || StorageService.getCart();

      finalCartItems = localCartItems;
      const cartItemCount = localCartItems.reduce((total, cartItem) => {
        return total + (Number(cartItem.quantity) || 0);
      }, 0);

      const couponDetails = StorageService.getCouponDetails() || {};

      const totalBeforeDiscount = localCartItems.reduce((total, cartItem) => {
        return total + cartItem.price_without_deal * (Number(cartItem.quantity) || 0);
      }, 0);

      let totalProductPrice = 0;
      let totalOnlyProductPrice = 0; // New variable to skip items with a bundle_id
      const calculatedAmount = localCartItems.reduce((total, cartItem) => {
        // Calculate totalProductPrice for all items
        totalProductPrice += cartItem.price * (Number(cartItem.quantity) || 0);

        // Calculate totalOnlyProductPrice, skipping items with a bundle_id
        if (!cartItem.bundle_id) {
          totalOnlyProductPrice += cartItem.price * (Number(cartItem.quantity) || 0);
        }
        let finalPrice = cartItem.price;
        if (
          !cartItem.is_pre_deal_available &&
          !cartItem.is_deal_available &&
          couponDetails.slash_price &&
          (isEmpty(couponDetails.product_ids) ||
            (Array.isArray(couponDetails.product_ids) &&
              findIndex(couponDetails.product_ids, o => parseInt(o, 10) === parseInt(cartItem.product_id, 10)) > -1))
        ) {
          const { discount = 0 } = couponDetails;
          if (!cartItem.bundle_id) {
            finalPrice = (cartItem.price * ((100 - discount) / 100)).toFixed(2);
          }
        } else if (!cartItem.is_pre_deal_available && !cartItem.is_deal_available && cartItem.offer_category_id) {
          let quantity = 0;
          localCartItems.forEach(item => {
            if (item.offer_category_id == cartItem.offer_category_id && !item.is_pre_deal_available && !item.is_deal_available) {
              quantity += Number(cartItem.quantity) || 0;
            }
          });
          const volumeDiscount = (() => {
            if (cartItem) {
              const discounts = [...cartItem.category_volume_discounts].sort((a, b) => b.units - a.units);
              let finalDiscount = 0;
              discounts.some(discount => {
                if (quantity >= discount.units) {
                  finalDiscount = discount.discount;
                  return true;
                }
              });
              return finalDiscount;
            }
          })();
          finalPrice = (finalPrice * (100 - volumeDiscount)) / 100;
        }
        return total + finalPrice * cartItem.quantity;
      }, 0);

      const totalDiscountApplied = totalBeforeDiscount - calculatedAmount;
      const { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE } = appConfig
      const cartData = {
        cart_items: localCartItems,
        cart_item_count: cartItemCount,
        totalBeforeDiscount,
        amount: totalProductPrice.toFixed(2), // Total cart product price
        calculatedAmount,
        totalDiscountApplied,
        totalWithCannabisTax: calculateTotalPriceWithTax(localCartItems || [], { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE }),
        productsAmount: totalOnlyProductPrice.toFixed(2) // New key for total excluding bundles
      };

      if (localCartItems?.length) {
        const invoiceCardItems = [];
        localCartItems.map(res => {
          if (res.bundle_id) {
            invoiceCardItems.push({ quantity: res.quantity, bundle_id: res.bundle_id });
          } else {
            invoiceCardItems.push({ product_id: res.product_id, quantity: res.quantity });
          }
        });

        await getInvoiceDataForLoggedOutUsers(cartData, invoiceCardItems);
        dispatchRedux(setCartData(cartData));
      } else {
        dispatchRedux(setCartData(cartData));
      }

      dispatchRedux(setViewCartLoading(false));

    }

    if (finalCartItems?.length) {
      const cartType = getCartType(finalCartItems);

      if (isLoggedIn && finalAsapCount == 0 && finalCartItems.length) {
        if (cartType !== ASAP) {
          // dispatch({
          //   type: 'saveLocation',
          //   payload: {
          //     ...deliveryDetails,
          //     deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
          //   }
          // });
          dispatchRedux(saveLocation({
            ...deliveryDetails,
            deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
          }))
        }
      } else if (cartType === ASAP_AND_SCHEDULE) {
        if (deliveryType) {
          if (Number(deliveryType) == SCHEDULE_TYPE_FUTURE) {
            // dispatch({
            //   type: 'saveLocation',
            //   payload: {
            //     ...deliveryDetails,
            //     deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
            //   }
            // });
            dispatchRedux(saveLocation({
              ...deliveryDetails,
              deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
            }))
          } else {
            // dispatch({
            //   type: 'saveLocation',
            //   payload: {
            //     ...deliveryDetails,
            //     deliveryTypeSelected:
            //       deliveryTypeSelected === SCHEDULE_TYPE_ASAP_PRIORITY ? SCHEDULE_TYPE_ASAP_PRIORITY : SCHEDULE_TYPE_ASAP
            //   }
            // });
            dispatchRedux(saveLocation({
              ...deliveryDetails,
              deliveryTypeSelected:
                deliveryTypeSelected === SCHEDULE_TYPE_ASAP_PRIORITY ? SCHEDULE_TYPE_ASAP_PRIORITY : SCHEDULE_TYPE_ASAP
            }))
          }
        }
      } else if (cartType === SCHEDULE) {
        // dispatch({
        //   type: 'saveLocation',
        //   payload: {
        //     ...deliveryDetails,
        //     deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
        //   }
        // });
        dispatchRedux(saveLocation({
          ...deliveryDetailsRef.current,
          deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
        }))
      } else if (asapEnabled) {
        // dispatch({
        //   type: 'saveLocation',
        //   payload: {
        //     ...deliveryDetails,
        //     deliveryTypeSelected:
        //       deliveryTypeSelected === SCHEDULE_TYPE_ASAP_PRIORITY ? SCHEDULE_TYPE_ASAP_PRIORITY : SCHEDULE_TYPE_ASAP
        //   }
        // });
        dispatchRedux(saveLocation({
          ...deliveryDetails,
          deliveryTypeSelected:
            deliveryTypeSelected === SCHEDULE_TYPE_ASAP_PRIORITY ? SCHEDULE_TYPE_ASAP_PRIORITY : SCHEDULE_TYPE_ASAP
        }))
      } else if (!asapEnabled) {
        // dispatch({
        //   type: 'saveLocation',
        //   payload: {
        //     ...deliveryDetails,
        //     deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
        //   }
        // });
        dispatchRedux(saveLocation({
          ...deliveryDetails,
          deliveryTypeSelected: SCHEDULE_TYPE_FUTURE
        }))
      }
    }
    mutatePurchaseLimit();

    // dispatch({ type: 'setViewCartLoading', payload: false });
    dispatchRedux(setViewCartLoading(false))
    // dispatch({ type: 'setCartLoading', payload: false });
    dispatchRedux(setCartLoading(false))
  }

  async function getInvoiceDataForFutureCart(invoiceCardItems) {
    // const {
    //   user: {
    //     deliveryDetails: { postcode = DEFAULT_ZIPCODE }
    //   }
    // } = newstate.current;
    const { deliveryDetails: { postcode = DEFAULT_ZIPCODE } } = userState.current

    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/guest/invoice/${postcode}`,
      method: 'POST',
      data: {
        requestProducts: invoiceCardItems
      },
      hideSuccess: true,
      cancelRequestUrl: GET_LOGOUT_INVOICE,
      hideAuthToken: true
    });
    const totalFeesIncluded = get(response, 'data.totalFeesIncluded', 0);
    return parseFloat(totalFeesIncluded).toFixed(2);
  }

  async function getTeslaCampaignInfo() {
    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/tesla_campaign_info`,
      method: 'GET',
      hideSuccess: true
    });
    const teslaContextData = get(response, 'data.data', {});
    return teslaContextData;
  }

  async function getDailyDealsProducts(tab) {
    // const {
    //   user: {
    //     deliveryDetails: { postcode = DEFAULT_ZIPCODE }
    //   }
    // } = newstate.current;

    const { deliveryDetails: { postcode = DEFAULT_ZIPCODE } } = userState.current

    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/full_shop_category/${tab}/deals/${postcode}?sort_key=deals_first&order=asc`,
      method: 'GET',
      hideSuccess: true
    });
    const dealsResponse = get(response, 'data.categories', {});
    return dealsResponse;
  }

  async function getInvoiceDataForLoggedOutUsers(cartData, invoiceCardItems) {
    // const {
    //   user: {
    //     deliveryDetails: { postcode = DEFAULT_ZIPCODE }
    //   }
    // } = newstate.current;

    const { deliveryDetails: { postcode = DEFAULT_ZIPCODE } } = userState.current

    const {
      query: { giftcard }
    } = routerRef.current;

    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/guest/invoice/${giftcard ? DEFAULT_GIFTCARD_ZIPCODE : postcode}`,
      method: 'POST',
      data: {
        requestProducts: invoiceCardItems
      },
      hideSuccess: true,
      cancelRequestUrl: GET_LOGOUT_INVOICE
    });

    // response && dispatch({ type: 'setCartData', payload: { ...cartData, ...response?.data } });
    response && dispatchRedux(setCartData({ ...cartData, ...response?.data }));
  }

  const renderOunceDiscountPopup = ({ ounceCreatedObj }) => {
    const { ounce_type: ounceType, schedule_type: ounceScheduleType } = ounceCreatedObj;
    dispatchRedux(pushNotification({
      type: notificationTypes.ounceDiscountPopup,
      data: {
        ounceCount: ounceType === CustomOunceTypes.HALF.type ? CustomOunceTypes.HALF.count : CustomOunceTypes.FULL.count,
        deliveryTypeText: ounceScheduleType === ScheduleType.ASAP ? 'express' : 'scheduled'
      }
    }))
  };

  const setGetGuestCart = async ({ product, hideSuccess, onSuccess }) => {
    // const {
    //   user: { userSelectedTab }
    // } = newstate.current;
    const { userSelectedTab } = userState.current

    const cart = StorageService.getCart();
    const previousCart = [...cart];
    try {
      let updateProductIndex = -1;
      const isOunceProduct = !!product.ounce_id;

      // dispatch({ type: 'setAddingToCart', payload: true });
      dispatchRedux(setAddingToCart(true))

      if (!isOunceProduct) {
        // dispatch({ type: 'addUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });
        dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))

        updateProductIndex = cart.findIndex(item => {
          if (product?.bundle_id && item.bundle_id === product.bundle_id) {
            return true;
          }

          if (product?.product_id && item.product_id === product.product_id) {
            return true;
          }

          return false;
        });
      }

      if (updateProductIndex > -1) {
        cart[updateProductIndex] = product;
      } else {
        cart.push(product);
      }

      StorageService.setCart(cart); // Temporarily save to get updated cart on succesive addToCart calls

      const cartPayload = cart.map(item => {
        if (!item?.ounce_id) {
          return {
            product_id: item?.product_id || undefined,
            bundle_id: item?.bundle_id || undefined,
            schedule_type: item?.schedule_type,
            quantity: item.quantity
          };
        }

        return item;
      });

      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/guest/cart`,
        method: 'POST',
        data: { cart: cartPayload, user_selected_delivery_type: userSelectedTab === SCHEDULE ? 2 : 1 },
        hideSuccess,
        cancelRequestUrl: GET_CART
      });

      if (!isOunceProduct) {
        // dispatch({ type: 'removeUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });

        dispatchRedux(removeUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))
      }

      const { cart: updatedCart = [], ounce_created: ounceCreatedObj } = response?.data || {};

      StorageService.setCart(updatedCart); // Update cart with the successful api response

      if (!hideSuccess) {
        toast.success('Successfully updated quantity.');
      }

      if (onSuccess) {
        onSuccess();
      }

      getCart();
    } catch (error) {
      // Revert to previous cart if error
      StorageService.setCart(previousCart);
      getCart();

      setRollbarWarning('API.js_setGetGuestCart_catch', error);
      console.error('API.js_setGetGuestCart_catch', error);
    } finally {
      // dispatch({ type: 'setAddingToCart', payload: false });
      dispatchRedux(setAddingToCart(false))
    }
  };

  async function applyCoupon(
    coupon = 0,
    {
      showToast,
      branch,
      cancelRequestUrl = GET_CART,
      ignoreCoupon = 0,
      // createDynamicOunce,
      // dynamicOunceDeliveryType,
      preventGetCart = false,
      onCheckout,
      autoApplied = false
    } = {}
  ) {
    // const {
    //   user: {
    //     isLoggedIn,
    //     deliveryDetails: { postcode = DEFAULT_ZIPCODE, deliveryTypeSelected, deliveryDate, deliveryTime },
    //     isNewUserWithPromotion = false,
    //     hideError
    //   }
    // } = newstate.current;
    const {
      isLoggedIn,
      deliveryDetails: { postcode = DEFAULT_ZIPCODE, deliveryTypeSelected, deliveryDate, deliveryTime },
      isNewUserWithPromotion = false,
      hideError
    } = userState.current

    const { happyHours } = deliveryDetailsRef.current;

    const {
      pathname,
      query: { hideCoupon = false }
    } = routerRef.current;

    if (!isLoggedIn) {
      return;
    }

    let clientId = '';

    if (window.analytics && typeof ga === 'function') {
      // eslint-disable-next-line func-names
      ga(function (tracker) {
        clientId = tracker.get('clientId');
      });
    }

    const selectedDelivery = deliveryTypeSelected;
    let scheduleTime;

    if (
      selectedDelivery === SCHEDULE_TYPE_FUTURE &&
      deliveryDetailsData.deliveryDays &&
      deliveryDetailsData.deliveryDays.length &&
      deliveryDate >= 0 &&
      deliveryDetailsData.deliveryDays[deliveryDate] &&
      deliveryDetailsData.deliveryDays[deliveryDate].deliveryWindows &&
      deliveryDetailsData.deliveryDays[deliveryDate].deliveryWindows.length
    ) {
      const dateTimeObj = find(deliveryDetailsData.deliveryDays[deliveryDate].deliveryWindows, { startTime: deliveryTime });
      scheduleTime = dateTimeObj
        ? dateTimeObj.dateTime
        : deliveryDetailsData.deliveryDays[deliveryDate].deliveryWindows[0].dateTime;
    } else if (selectedDelivery !== SCHEDULE_TYPE_ASAP) {
      scheduleTime = getFirstAvailableTimeSlot(-1, true, false);
    }

    const params = { cart_type: (deliveryTypeSelected % 10) + 2, ignore_coupon: ignoreCoupon, schedule_time: scheduleTime };

    if (clientId) {
      params.clientId = clientId;
    }

    let callGetCart = true;

    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/orders/apply_discount/${coupon}/${postcode}`,
        method: 'GET',
        hideError,
        params,
        requestUrl: APPLY_DISCOUNT,
        cancelRequestUrl
      });

      // dispatch({ type: 'updateUserID', payload: response.data.data });

      dispatchRedux(updateUserID(response.data?.data))

      // const {
      //   cart: {
      //     cartDetails: { pastTipAmount },
      //     cartData: { totalHappyHourDiscount } = {}
      //   }
      // } = newstate.current;
      const {
        cartDetails: { pastTipAmount },
        cartData: { totalHappyHourDiscount } = {}
      } = cartState.current

      const happyHoursDetails = { totalHappyHourDiscount, happyHoursCode: happyHours.code };

      if (response) {
        const receivedcouponData = get(response, 'data.data', {});
        const couponDataSchemaWithMsg = getCouponSchema(receivedcouponData, true, happyHoursDetails);
        const couponDataSchema = getCouponSchema(receivedcouponData, undefined, happyHoursDetails);
        const customerEmail = get(receivedcouponData, 'customer_email', '');
        const customerID = get(receivedcouponData, 'customer_id', '');
        const driverAutomaticTipAmount = get(receivedcouponData, 'driver_automatic_tip_amount', '');
        const userInfoLocal = StorageService.getUserData() || {};
        const couponDataError = get(response, 'data.data.error', null);

        if (receivedcouponData.amount && receivedcouponData.gift_card_count) {
          receivedcouponData.type = 2;
          if (onCheckout) {
            toast.success('Gift card redeemed successfully');
            getCart();
          } else {
            dispatchRedux(pushNotification({ type: notificationTypes.giftRedeemPopup, receivedcouponData }))
          }
          return { success: true, amount: receivedcouponData?.amount };
        }

        if (showToast) {
          displayCouponApplyMessage(couponDataSchemaWithMsg);
        }

        if (isNewUserWithPromotion && receivedcouponData.error) {
          // if coupon is invalid, dont show thank you popup
          // dispatch({ type: 'setCouponData', payload: { isNewUserWithPromotion: false } });
          dispatchRedux(setCouponData({ isNewUserWithPromotion: false }))
        }

        if (isNewUserWithPromotion && !receivedcouponData.error) {
          const marketingDetails = await getMarketingDetails();

          if (StorageService.getUserWithPromotion()) {
            dispatchRedux(pushNotification({ type: notificationTypes.promoOfferSuccess, data: { marketingDetails, isMarketing: true } }))
            StorageService.deleteUserWithPromotion();
          }

          // dispatch({ type: 'setCouponData', payload: { isNewUserWithPromotion: false } });
          dispatchRedux(setCouponData({ isNewUserWithPromotion: false }))

          toast.success('Thank you for submitting your info to us. Your code has been applied.', {
            toastId: 'coupanAppliedForNewUser'
          });
        }

        if (coupon) {
          // because coupon is applied as 0 when coupon is removed, hence checked for truthy coupon value

          if (isCheckoutOnly && pathname === '/webview-connect' && !couponDataError) {
            StorageService.setCouponFlag(hideCoupon === 'true');
          }

          const eventData = {
            order_id: userInfoLocal.phone,
            cart_id: userInfoLocal.phone,
            coupon_id: couponDataSchema.coupon_type,
            coupon_name: couponDataSchema.discount_code,
            discount: couponDataSchema.discount,
            coupon_hidden: isCheckoutOnly ? StorageService.getCouponFlag() : undefined
          };
          if (autoApplied) eventData.mode = 'auto-applied';
          trackEvent('Coupon Applied', eventData);
        }

        if (couponDataError && couponDataError.message) {
          const eventData = {
            order_id: userInfoLocal.phone,
            cart_id: userInfoLocal.phone,
            coupon_id: coupon,
            reason: couponDataError.message
          };
          if (autoApplied) eventData.mode = 'auto-applied';
          trackEvent('Coupon Denied', eventData);
        }
        if (router.pathname.includes('confirmation')) {
          // dispatch({ type: 'setCouponData', payload: { couponData: {} } });
          dispatchRedux(setCouponData({ couponData: {} }))
          StorageService.deleteCouponDetails();
        } else {
          // dispatch({ type: 'setCouponData', payload: { couponData: couponDataSchemaWithMsg } });
          dispatchRedux(setCouponData({ couponData: couponDataSchemaWithMsg }))
          StorageService.setCouponDetails(couponDataSchema);
        }
        // return { couponData: couponDataSchemaWithMsg };
        if (customerEmail) {
          // dispatch({ type: 'setCustomerInfo', payload: { email: customerEmail } });
          dispatchRedux(setCustomerInfo({ email: customerEmail }))
        }

        if (customerID) {
          // dispatch({ type: 'setCustomerInfo', payload: { customer_id: customerID } });
          dispatchRedux(setCustomerInfo({ customer_id: customerID }))
        }

        if (driverAutomaticTipAmount) {
          if (driverAutomaticTipAmount !== pastTipAmount) {
            // dispatch({
            //   type: 'setCartDetails',
            //   payload: { driverTip: driverAutomaticTipAmount, pastTipAmount: driverAutomaticTipAmount }
            // });
            dispatchRedux(setCartDetails({ driverTip: driverAutomaticTipAmount, pastTipAmount: driverAutomaticTipAmount }))
          }
        } else if (pastTipAmount) {
          // dispatch({
          //   type: 'setCartDetails',
          //   payload: { pastTipAmount: 0 }
          // });
          dispatchRedux(setCartDetails({ pastTipAmount: 0 }))
        }

        if (branch) {
          dispatchRedux(pushNotification({ type: notificationTypes.linkOffer, data: branch }));
        }
        StorageService.setAppliedCoupons(receivedcouponData?.discount_applied || []);
        const isAvailed = (couponDataSchema && couponDataSchema?.couponAvailed) || false;
        return { success: isNull(couponDataError) && isAvailed };
      }
    } catch (error) {
      // Incase if axios request is cancelled
      // Cancelled request returns empty error object
      if (!isEmpty(error)) {
        // TODO: What happens if coupon is not applied?
        const userInfoLocal = StorageService.getUserData() || {};
        const eventData = {
          order_id: userInfoLocal.phone,
          cart_id: userInfoLocal.phone,
          coupon_id: coupon,
          reason: error
        };
        if (autoApplied) eventData.mode = 'auto-applied';
        trackEvent('Coupon Denied', eventData);
      } else {
        callGetCart = false;
      }

      return { success: false };
    } finally {
      if (callGetCart && !preventGetCart) {
        await getCart(null, true);
      }
    }
  }

  async function checkProductAvailability({
    postcode: paramPostCode,
    latitude: paramLatitude,
    longitude: paramLongitude,
    scheduleType,
    checkAvailability = false
  }) {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;

    const { isLoggedIn } = userState.current

    try {
      if (isLoggedIn) {
        let url = `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/product_availability/${paramPostCode}/${(scheduleType % 10) + 2
          }`;

        if (checkAvailability) {
          url = `${url}?check_availability=1`;
        }

        const response = await axios({
          url,
          method: 'GET',
          hideError: true
        });

        const {
          data: {
            data: { are_all_products_available: areAllProductsAvailable, data }
          }
        } = response;

        if (!areAllProductsAvailable) {
          const outOfStockProducts = [];

          if (scheduleType === SCHEDULE_TYPE_ASAP) {
            trackEvent('ASAP Sold Out', {
              products: data,
              postcode: paramPostCode,
              latitude: paramLatitude,
              longitude: paramLongitude
            });
          }

          data.forEach(element => {
            if (element.ounce_id && element.products?.length) {
              // const ounceType =
              //   element.ounce_type === CustomOunceTypes.HALF.count ? CustomOunceTypes.HALF.label : CustomOunceTypes.FULL.label;

              element.products.forEach(ounceItem => {
                const isRequiredUnavailable = Number(ounceItem.requested_quantity) > Number(ounceItem.available_quantity);

                if (isRequiredUnavailable) {
                  outOfStockProducts.push({
                    ordered_quantity: Number(ounceItem.requested_quantity),
                    available_quantity: Number(ounceItem.available_quantity),
                    is_sold_out: !Number(ounceItem.available_quantity),
                    product_id: ounceItem.product_id,
                    name: ounceItem.product_name,
                    image: element?.product_image || '', // CustomOunceTypes[ounceType.toUpperCase()].img,
                    image_alt_tag: element?.image_alt_tag || element?.product_name || '' //  `${capitalizeFirstLetter(ounceType)} Ounce`
                  });
                }
              });
            } else {
              const isSoldOut = parseInt(element.requested_quantity, 10) > parseInt(element.available_quantity, 10);

              if (isSoldOut) {
                outOfStockProducts.push({
                  ordered_quantity: element.requested_quantity,
                  available_quantity: element.available_quantity,
                  is_sold_out: isSoldOut,
                  product_id: element.product_id,
                  name: element.product_name,
                  image: element.product_image,
                  image_alt_tag:
                    element.image_alt_tag && element.image_alt_tag !== '' ? element.image_alt_tag : element.product_name
                });
              }
            }
          });

          // dispatch({ type: 'setOutOfStockProducts', payload: outOfStockProducts });
          dispatchRedux(setOutOfStockProducts(outOfStockProducts))

          getCart();
        } else {
          // dispatch({ type: 'setOutOfStockProducts', payload: [] });
          dispatchRedux(setOutOfStockProducts([]))
        }

        // dispatch({ type: 'setCartSwitched', payload: { cartSwitched: false } });
        dispatchRedux(setCartSwitched({ cartSwitched: false }))
        return { data: response.data };
      }
    } catch (error) {
      const { data } = error;

      // dispatch({ type: 'setCartSwitched', payload: { cartSwitched: false } });
      dispatchRedux(setCartSwitched({ cartSwitched: false }))
      if (data && data.code !== 11110103) {
        toast.error(data.message);
      }
      if (data && data.code === 11120033) {
        dispatchRedux(pushNotificationOverlap({
          type: notificationTypes.switchDeliveryType,
          data: { defaultSelected: scheduleType == 0 ? SCHEDULE : ASAP }
        }))

        throw error;
      } else {
        getCart();
      }
    } finally {
      mutateWalletWithPurchaseRewards();
    }
  }

  async function switchPayment(paymentObj) {
    // try {
    await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/orders/payment_mode`,
      method: 'PATCH',
      data: paymentObj
    });
  }

  async function editAmbassadorBankDetails(data, frontId, backId) {
    let formData = null;
    if (frontId && backId) {
      formData = new FormData();
      formData.append('frontId', frontId);
      formData.append('backId', backId);
      Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    }
    const response = await axios({
      url: `${API_URL}/merchants/edit/account`,
      method: 'PUT',
      data: formData || data
    });
    if (response) {
      const merchantAccount = get(response, 'data.data', {});
      return { merchantAccount };
    }
    return {};
  }

  async function sendAmbassadorBankDetails(data, frontId, backId) {
    const formData = new FormData();
    formData.append('frontId', frontId);
    formData.append('backId', backId);
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    const response = await axios({
      url: `${API_URL}/merchants/create/account`,
      method: 'POST',
      data: formData
    });
    if (response) {
      const merchantAccount = get(response, 'data.data', {});
      return { merchantAccount };
    }
    return {};
  }

  async function addAmbassadorCardDetails(data) {
    const response = await axios({
      url: `${API_URL}/merchants/add/card`,
      method: 'POST',
      data
    });
    if (response) {
      const merchantAccount = get(response, 'data.data', {});
      return { merchantAccount };
    }
    return {};
  }

  async function addReferralCode(code) {
    const response = await axios({
      url: `${API_URL}/customers/ambassador_code`,
      method: 'POST',
      data: { code }
    });
    if (response) {
      const referralCodeData = get(response, 'data.data', {});
      return { referralCodeData };
    }
    return {};
  }

  async function deleteReferralCode(codeId) {
    await axios({
      url: `${API_URL}/customers/ambassador_code/${codeId}`,
      method: 'DELETE'
    });
  }

  // async function encashReferralAmount(data) {
  //   const response = await axios({
  //     url: `${API_URL}/customers/cash_out`,
  //     method: 'POST',
  //     data
  //   });
  //   if (response) {
  //     const data = get(response, 'data.data', {});
  //     toast.success(data.message);
  //   }
  // }

  async function encashReferralAmount() {
    const response = await axios({
      url: `${API_URL}/customers/cash_out`,
      method: 'GET'
    });
    if (response) {
      const data = get(response, 'data.data', {});
      toast.success(data.message);
    }
  }

  const switchCartItems = async ({
    product,
    type,
    quantity = null,
    singleOption = null,
    hideSuccess = true,
    onOunceCreationSucess = null
  }) => {
    // const {
    //   user: { isLoggedIn, deliveryDetails, couponData }
    // } = newstate.current;
    const { isLoggedIn, deliveryDetails, couponData } = userState.current

    if (isLoggedIn) {
      try {
        const deliveryType = type === ASAP ? SCHEDULE_TYPE_ASAP : SCHEDULE_TYPE_FUTURE;
        // Prepare the payload
        const payload = {};
        if (product?.ounceObj) {
          payload.ounce = { ...product.ounceObj };
        } else if (product?.bundle_id) {
          payload.product_id = product.bundle_id;
          payload.is_bundle = 1;
          payload.quantity = quantity || undefined;
        } else if (product?.product_id) {
          payload.product_id = product.product_id;
          payload.is_bundle = 0;
          payload.quantity = quantity || undefined;
        }
        // dispatch({ type: 'setCartSwitched', payload: { cartSwitched: true } });
        dispatchRedux(setCartSwitched({ cartSwitched: true }))

        // Switch the cart type and add product
        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/${deliveryType + 2}/switch_cart`,
          method: 'PATCH',
          data: payload,
          hideSuccess
        });

        if (response) {
          // notifying user if product is removed from save for later while adding to cart
          if (response?.data?.is_product_removed_from_saved_for_later) {
            dispatch({ type: 'setIsSavedForLaterItemAddedOrRemoved', payload: true });
            dispatchRedux(setIsSavedForLaterItemAddedOrRemoved(true));
            await getSaveForLaterItems();
            wishlistToast(`Product Removed from Saved For Later list`);
          }
          // dispatch({ type: 'setCartSwitched', payload: { cartSwitched: true } });
          dispatchRedux(setCartSwitched({ cartSwitched: true }))
          const couponCode = couponData.discount_code || '0';

          if (singleOption) {
            await applyCoupon(couponCode);
            // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, deliveryTypeSelected: deliveryType } });
            dispatchRedux(saveLocation({ ...deliveryDetails, deliveryTypeSelected: deliveryType }))
          } else {
            // dispatch({ type: 'saveLocation', payload: { ...deliveryDetails, deliveryTypeSelected: deliveryType } });
            dispatchRedux(saveLocation({ ...deliveryDetails, deliveryTypeSelected: deliveryType }))
            applyCoupon(couponCode);
          }
        } else if (singleOption) {
          await getCart();
        } else {
          getCart();
        }

        if (onOunceCreationSucess) {
          onOunceCreationSucess();
        }
      } catch (error) {
        getCart();

        throw error;
        // Do nothing
      }
    } else {
      let updatedProduct = product;
      const deliveryType = type === ASAP ? ASAP : SCHEDULE;

      if (product?.ounceObj) {
        // To switch to new ounce in looged out state, first new ounce needs to be created to get ounceId
        updatedProduct = await createOunce({
          ounceObj: product.ounceObj,
          preventDeliveryTypeCheck: true,
          preventLocalCartUpdation: true,
          hideSuccess: true
        });
      }

      StorageService.clearCartProducts(deliveryType, updatedProduct);
      if (StorageService.isInSaveForLater(updatedProduct)) {
        removeFromSaveForLater({ item: updatedProduct });
        getSaveForLaterItems();
      }

      if (singleOption) {
        await getCart();
      } else {
        getCart();
      }
    }
  };

  async function emailSubscribe(email) {
    // const {
    //   user: {
    //     isLoggedIn,
    //     deliveryDetails: { address, city, country, postcode = DEFAULT_ZIPCODE, state, placeId },
    //     userInfo: { phone, country: countryCode }
    //   }
    // } = newstate.current;
    const {
      isLoggedIn,
      deliveryDetails: { address, city, country, postcode = DEFAULT_ZIPCODE, state, placeId },
      userInfo: { phone, country: countryCode }
    } = userState.current
    let anonymousId;
    const data = {
      address,
      zipcode: postcode,
      city,
      state,
      country,
      placeId,
      customerNumber: phone,
      customerCountryCode: countryCode,
      email
    };
    if (!isLoggedIn && window.analytics && window.analytics.user) {
      anonymousId = window.analytics.user().anonymousId();
      data.anonymousId = anonymousId;
    }
    try {
      const res = await axios({
        url: `${API_URL}/notify_users`,
        method: 'POST',
        data
      });
      if (res) {
        if (res.data && res.data.redirectUrl && res.data.redirectUrl !== '') {
          let url = res.data.redirectUrl;
          if (url.toLowerCase().indexOf('http://') == -1 && url.toLowerCase().indexOf('https://') == -1) {
            url = `https://${url}`;
          }
          setTimeout(function () {
            window.location = url;
          }, 5000);
        }
        if (window.analytics && !isLoggedIn) {
          window.analytics.identify(
            {
              out_of_delivery_zip: [postcode],
              out_of_delivery_email: [email],
              email
            },
            {
              integrations: {
                All: false,
                Appboy: true
              }
            }
          );
          if (window.analytics && window.appboy) {
            setTimeout(() => {
              window.appboy.requestImmediateDataFlush();
            }, 1000);
          }
        }
      }
    } catch (err) {
      process.env.NEXT_PUBLIC_ENV === "development" && console.log(err);
    }
    // if (window.analytics && window.appboy && !isLoggedIn) {
    //   window.appboy.getUser().setEmail(email);
    //   window.appboy.getUser().setCustomUserAttribute('out_of_delivery_zip', [postcode]);
    //   window.appboy.getUser().setCustomUserAttribute('out_of_delivery_email', [email]);
    // }
  }

  async function enterPACNumber(paymentObj) {
    await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/hypur`,
      method: 'POST',
      data: paymentObj
    });
  }

  async function verifyCustomerSignature(data) {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/orders/${data.orderId}/signature`,
        method: 'POST',
        hideSuccess: true,
        data,
        excludeLocationToken: true
      });
      return { data: res, error: null };
    } catch (err) {
      return { data: null, error: err };
    }
  }

  async function rateDriver(data) {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/orders/${data.orderId}/ratings`,
        method: 'POST',
        data,
        hideSuccess: true
      });
      return { data: res, error: null };
    } catch (err) {
      return { data: null, error: err };
    }
  }

  async function getMarketingDetails() {
    const response = await axios({
      url: `${API_URL}/customers/marketing_details`,
      method: 'GET'
    });
    if (response) {
      const mktDtls = get(response, 'data.data.marketing_details', {});
      return mktDtls;
    }
    return {};
  }

  async function validateCoupon(coupon) {
    // const {
    //   user: { isLoggedIn, couponData }
    // } = newstate.current;
    const { isLoggedIn, couponData } = userState.current
    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/coupon/${coupon}`,
      method: 'GET'
    });
    if (response) {
      /**
       * Make sure that you are deleting same coupon which was checked
       * Because of API response time difference a valid coupon code
       * can be replaced if user comes through a Branch.IO link
       */
      const isExpired = get(response, 'data.data.is_expired', false);
      if (response && isExpired && couponData && couponData.discount_code === coupon) {
        StorageService.deleteCouponDetails();
        if (isLoggedIn) {
          applyCoupon(0, false);
        }
      }
    }
  }

  async function syncLocalCartData(normalProducts = [], ounceProducts = [], scheduleType) {
    // const {
    //   user: {
    //     userSelectedTab,
    //     deliveryDetails: { postcode = DEFAULT_ZIPCODE, deliveryTypeSelected }
    //   }
    // } = newstate.current;
    const {
      userSelectedTab,
      deliveryDetails: { postcode = DEFAULT_ZIPCODE, deliveryTypeSelected }
    } = userState.current
    try {
      let userSeletedDeliveryType;

      await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/updatecart`,
        method: 'POST',
        data: {
          product: normalProducts,
          zip_code: postcode,
          schedule_type: scheduleType || (deliveryTypeSelected % 10) + 2,
          ounce_products: ounceProducts,
          user_selected_delivery_type: userSeletedDeliveryType // used for dynamic ounce creation in the case of mixed cart
        }
      });

      await getCart();
      StorageService.deleteCart();
    } catch (error) {
      const { data } = error;
      await getCart();
      if (data && data.code === 11120033) {
        dispatchRedux(pushNotification({ type: notificationTypes.switchDeliveryType, data: { defaultSelected: ASAP } }))
      }
      StorageService.deleteCart();
    }
  }

  async function setReferralLink(token) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/apply_referral`,
        method: 'PATCH',
        data: { referrer_token: token }
      });

      if (response) {
        const {
          data: {
            data: {
              display_message: { order_message: message, order_title: title } = {},
              discount_value: discountValue = '$0'
            } = {}
          }
        } = response;

        // show toast instead of popup for zipps in logged-in state
        if (title && message && !isZipps) {
          dispatchRedux(pushNotification({ type: notificationTypes.promoOffer, data: { title, text: message, discountValue } }))
        }

        await applyCoupon(0);
      }
    } catch (error) {
      // throw new APIException(error);
      await applyCoupon(0);
    }
  }

  async function getReferralBranchLinkData(token) {
    const response = await axios({
      url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/referral/discounts/${token}`,
      method: 'GET'
    });
    if (response) {
      const receivedcouponData = getBranchSchema(get(response, 'data.data'));
      const code = get(response, 'data.code', 0);
      if (code !== 11120017) {
        receivedcouponData.token = token;
        // dispatch({ type: 'setCouponData', payload: { couponData: receivedcouponData } });
        dispatchRedux(setCouponData({ couponData: receivedcouponData }))
        StorageService.setCouponDetails(receivedcouponData);
        StorageService.setAutoApplyCoupon();
        if (receivedcouponData.discount_code && receivedcouponData?.discount_code !== '') {
          const lastAppliedCouponObject = {
            customer_code: receivedcouponData?.discount_code,
            discount: receivedcouponData?.discount,
            type: receivedcouponData.coupon_type,
            description: ''
          };
          StorageService.setLastAppliedCoupon(lastAppliedCouponObject);
          // dispatch({ type: 'setLastAppliedCouponData', payload: { lastAppliedCouponData: lastAppliedCouponObject } });
          dispatchRedux(setLastAppliedCouponData({ lastAppliedCouponData: lastAppliedCouponObject }))
        }
      } else {
        toast.error(get(response, 'data.message', 'Referral program for the user is blocked.'));
      }
      const isAmbassador = get(response, 'data.data.is_ambassador');
      const orderTitle = get(response, 'data.data.order_title');
      const orderMessage = get(response, 'data.data.order_message');
      const discountValue = response?.data?.data?.discount_value || '$0';
      if (!isAmbassador && orderTitle && orderMessage) {
        dispatchRedux(pushNotification({
          type: notificationTypes.promoOffer,
          data: {
            title: orderTitle,
            text: orderMessage,
            discountValue
          }
        }))
      }
    }
  }

  async function reorder(orderID) {
    // const {
    //   user: {
    //     deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    //     couponData
    //   },
    //   cart: { cartData: { cart_items: cartItems = [] } = {} }
    // } = newstate.current;

    const {
      deliveryDetails: { postcode = DEFAULT_ZIPCODE },
      couponData
    } = userState.current
    const { cartData: { cart_items: cartItems = [] } = {} } = cartState.current
    try {
      // Clear the last added product from Cart
      // dispatch({ type: 'setLastAddedProduct', payload: { product: null } });
      dispatchRedux(setLastAddedProduct({ product: null }))
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/reorder/${orderID}/${postcode}`,
        method: 'GET'
      });

      const outOfStockProducts = [];
      const tempOutOfStockProducts = get(response, 'data.outOfStockProducts', []);
      tempOutOfStockProducts.forEach(element => {
        const mainProduct = cartItems.find(cartItem => cartItem.product_id === element.product_id) || {};
        const { quantity = 0 } = mainProduct;
        outOfStockProducts.push({
          ordered_quantity: Number(quantity) + Number(element.requested_quantity),
          available_quantity: Number(element.added_quantity),
          is_sold_out: element.is_sold_out,
          product_id: element.product_id,
          name: element.name,
          image: element.image
        });
      });
      // dispatch({ type: 'setOutOfStockProducts', payload: outOfStockProducts });
      dispatchRedux(setOutOfStockProducts(outOfStockProducts))
    } catch (error) {
      setRollbarWarning('API.js_reorder_catch', error);
      console.error('API.js_reorder_catch', error);
    } finally {
      if (couponData.discount_code) {
        await applyCoupon(couponData.discount_code);
      } else {
        await getCart();
      }
    }
  }

  async function setDefaultCard(cardID, type = null) {
    let modifiedCardList = cardList.map(card => {
      if (card.group_id === Number(cardID)) {
        return { ...card, is_default: 1 };
      }
      return { ...card, is_default: 0 };
    });
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/payments`,
        method: 'PATCH',
        data: { card_id: cardID, type }
      });
      if (response) {
        const id = get(response, 'data.data', []).card_id;
        modifiedCardList = modifiedCardList.map(card => {
          if (card.group_id === Number(id)) {
            return { ...card, is_default: 1 };
          }
          return { ...card, is_default: 0 };
        });
      }
    } catch (error) {
      setRollbarWarning('API.js_setDefaultCard_catch', error);
      console.error('API.js_setDefaultCard_catch', error);
    }
  }

  async function subscribeToDeals({ name, email }) {
    try {
      const response = await axios({
        url: 'https://www.gddelivered.com/api.php',
        method: 'POST',
        data: { name, email }
      });
      if (response) {
        toast.success('Successfully subscribed to deals');
        return;
      }
    } catch (error) {
      toast.error('Please try later');
    }
  }

  async function subscribeNewsletter(data) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/subscribe_newsletter`,
        method: 'POST',
        data
      });
      if (response) {
        // const seoData = get(response, 'data.data', {}) || {};
        // dispatch({ type: 'setSeoData', payload: { ...seoData } });
      }
    } catch (error) {
      // Kuch mat karo
      setRollbarWarning('API.js_subscribeNewsletter_catch', error);
      console.error('API.js_subscribeNewsletter_catch', error);
      toast.error(error);
    }
  }

  async function fetchSiteData(initialBannerData = false) {
    try {
      if (initialBannerData) {
        // dispatch({ type: 'setSeoData', payload: { ...initialBannerData } });

        dispatchRedux(setSeoData({ ...initialBannerData }))
      } else {
        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/cms/carousel_details`,
          method: 'GET'
        });
        if (response) {
          const seoData = get(response, 'data.data', {}) || {};
          // dispatch({ type: 'setSeoData', payload: { ...seoData } });
          dispatchRedux(setSeoData({ ...seoData }))
        }
      }
    } catch (error) {
      // Kuch mat karo
    }
  }

  async function getCurrentCustomerNumber(zipcode) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/call_current_number/${zipcode}`,
        method: 'GET'
      });
      if (response) {
        const data = get(response, 'data.data', {});
        dispatch({ type: 'setCurrentCustomerNumber', payload: data.call_current_customer_number });
      }
    } catch (error) {
      setRollbarWarning('API.js_getCurrentCustomerNumber_catch', error);
      console.error('API.js_getCurrentCustomerNumber_catch', error);
    }
  }

  async function authenticateProfile(token) {
    try {
      const authToken = token || cookie.get(COOKIE_VARIABLES.token) || '';

      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/authenticate/profile`,
        method: 'GET',
        headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
      });

      if (response) {
        const data = get(response, 'data.data', {});
        const customerDetails = data.customer_details;

        if (!customerDetails?.telephone) {
          return false;
        }

        const userInfo = {
          name: customerDetails.name,
          phone: customerDetails.telephone,
          country: customerDetails.country_code,
          user_id: customerDetails.user_id,
          email: customerDetails?.customer_email || '',
          first_name: customerDetails.first_name
            ? customerDetails.first_name
            : customerDetails.name.substr(0, customerDetails.name.indexOf(' ')) === ''
              ? customerDetails.name
              : customerDetails.name.substr(0, customerDetails.name.indexOf(' ')),
          last_name: customerDetails.last_name
            ? customerDetails.last_name
            : customerDetails.name.substr(0, customerDetails.name.indexOf(' ')) === ''
              ? ''
              : customerDetails.name.substr(customerDetails.name.indexOf(' ') + 1)
        };

        StorageService.setUserData({ ...userInfo });

        // dispatch({ type: 'setCustomerInfo', payload: userInfo });
        dispatchRedux(setCustomerInfo({ ...userInfo }))
        // dispatch({ type: 'setUserInfo', payload: userInfo });
        dispatchRedux(setUserInfo(userInfo))
        return true;
      }

      return false;
    } catch (error) {
      setRollbarWarning('API.js_authenticateProfile', error);
      console.error('API.js_authenticateProfile', error);
      return false;
    }
  }

  async function createStrongholdToken() {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/strong_hold/customer/token`,
        method: 'GET'
      });
      if (response) {
        const data = get(response, 'data', {});
        // dispatch({ type: 'setStronholdData', payload: data });
        dispatchRedux(setStrongholdInfo(data))
      }
    } catch (error) {
      toast.error(error);
    }
  }

  async function createStrongholdAccount({ firstName, lastName }) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/strong_hold/customer`,
        method: 'POST',
        data: { first_name: firstName, last_name: lastName },
        cancelRequestUrl: CREATE_STRONGHOLD
      });
      if (response) {
        await getCart();
        await createStrongholdToken();
        const { data: { token = '' } = {} } = response;
        return token;
      }
    } catch (error) {
      toast.error(error);
    }
  }

  async function createKindTapAccount(token) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/kindtap/customer`,
        method: 'POST',
        data: { usertoken: token }
      });
      if (response) {
        await getCart();
        const { data = {} } = response;
        return data;
      }
    } catch (error) {
      toast.error(error);
    }
  }

  async function signupToDrive({ firstName, lastName, email, phoneNumber, location, repcaptchaToken }) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/drivers/sign_up_to_drive`,
        method: 'POST',
        data: {
          first_name: firstName,
          last_name: lastName,
          location,
          email,
          phone_number: phoneNumber,
          recaptcha: repcaptchaToken
        }
      });
      if (response) {
        return true;
      }
    } catch (error) {
      toast.error(error);
      return false;
    }
  }

  async function driverReferral({ driverName, name, email, phoneNumber, repcaptchaToken }) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/drivers/refer_driver`,
        method: 'POST',
        data: { email, name, driver_name: driverName, driver_phone_number: phoneNumber, recaptcha: repcaptchaToken }
      });
      if (response) {
        return true;
      }
    } catch (error) {
      toast.error(error);
      return false;
    }
  }

  async function fetchActiveLocations() {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/drivers/all_active_locations`,
        method: 'GET'
      });
      if (response) {
        const locationsData = get(response, 'data.data', []) || [];
        return locationsData;
      }
    } catch (error) {
      toast.error(error);
    }
  }

  async function deleteStrongholdBankAccount(paymentSourceId) {
    try {
      await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/strong_hold/customer/${paymentSourceId}`,
        method: 'DELETE'
      });
    } catch (error) {
      toast.error(error);
    }
  }

  async function logOut() {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/authenticate/logout`,
        method: 'POST'
      });
      if (response) {
        // TO-DO
        StorageService.removeFriendsSession();
        StorageService.setFnfLocal(false);
        StorageService.clearRecipients();
      }
    } catch (error) {
      setRollbarWarning('API.js_logOut_catch', error);
      console.error('API.js_logOut_catch', error);
    }
  }

  async function fetchCashBacks() {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/discounts`,
        method: 'GET'
      });
      if (response) {
        const data = get(response, 'data', {});
        dispatch({ type: 'setCashbackData', payload: data });
      }
    } catch (error) {
      toast.error(error);
    }
  }

  async function confirmMmicUser(mmicUser) {
    try {
      await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/edit/usage_type`,
        method: 'POST',
        data: {
          status: mmicUser ? 1 : 0
        },
        hideSuccess: true
      });
      await getCart();
    } catch (error) {
      toast.error(error);
      return false;
    }
  }

  async function applyExistingGiftCards() {
    const response = await axios({
      url: `${API_URL}/orders/apply_gift_card`,
      method: 'POST'
    });
    if (response) {
      const data = get(response, 'data.data', {});
      if (data.amount && data.gift_card_count) {
        data.type = 1;
        // dispatch({ type: 'setLoading', payload: false });

        trackEvent(`Gift card Redeemed`, {
          is_referral_gc: data.is_referral_gc ? data.is_referral_gc : 0
        });
        dispatchRedux(pushNotification({ type: notificationTypes.giftRedeemPopup, data }))

      }
    }
    return {};
  }

  async function redeemSingleGiftCard(coupon) {
    const response = await axios({
      url: `${API_URL}/orders/redeem_gift_card`,
      method: 'POST',
      data: { gift_card_code: coupon }
    }).catch(function () {
      return { success: false };
    });

    if (response) {
      const data = get(response, 'data.data', {});
      data.type = 2;
      if (data.amount && data.gift_card_count) {
        trackEvent(`Single Gift Card Redeemed`);
        dispatchRedux(pushNotification({ type: notificationTypes.giftRedeemPopup, data }));

        return { success: true };
      }
    } else {
      return { success: false };
    }
  }

  async function getWeedyMedical(data, dismiss) {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => formData.append(key, value));
      formData.append('isBase64Image', false);

      const response = await axios({
        url: `${API_URL}/weedy`,
        headers: {
          'content-type': 'multipart/form-data'
        },
        method: 'POST',
        data: formData
      });

      if (response.status === 200) {
        // const status = get(response, 'data.status');
        dismiss();
        getCart();
        mutatePrescription();
        mutateWeedyStatus();
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const isInvalidOunce = ({ ounceProduct, preventDeliveryTypeCheck, onOunceCreationSucess }) => {
    // const {
    //   cart: { cartData: { cart_items: cartItems = [] } = {} }
    // } = newstate.current;

    const { cartData: { cart_items: cartItems = [] } = {} } = cartState.current

    if (!validZipcode) {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.deliverAddressPopup }))
      return true;
    }

    if (!preventDeliveryTypeCheck && !hasValidDeliveryType(cartItems, ounceProduct)) {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.switchDeliveryType, data: { onOunceCreationSucess } }))
      return true;
    }

    return false;
  };

  async function createOunce({
    ounceId,
    ounceObj,
    hideSuccess,
    onSuccess,
    preventGetCart,
    preventDeliveryTypeCheck,
    preventLocalCartUpdation
  }) {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;
    const { isLoggedIn } = userState.current

    const ounceProduct = {
      asap: Number(ounceObj?.schedule_type === ScheduleType.ASAP),
      schedule: Number(ounceObj?.schedule_type === ScheduleType.Scheduled),
      ounceObj: { ...ounceObj }
    };

    // dispatch({ type: 'setLastAddedProduct', payload: { product: ounceProduct } });

    dispatchRedux(setLastAddedProduct({ product: ounceProduct }))
    const hasError = isInvalidOunce({ ounceProduct, preventDeliveryTypeCheck, onOunceCreationSucess: onSuccess });
    if (hasError) {
      return null;
    }

    try {
      const response = await axios({
        url: `${API_URL}/carts/create/custom_ounce`,
        method: 'POST',
        data: { ...ounceObj, ounce_id: ounceId },
        hideSuccess
      });

      if (isLoggedIn) {
        if (response) {
          const data = response?.data || {};
          if (data?.are_all_products_available) {
            if (onSuccess) {
              onSuccess();
            }

            const ounceCreatedObj = response?.data?.ounce_created;
            if (ounceCreatedObj) {
              renderOunceDiscountPopup({ ounceCreatedObj });
            }

            if (!preventGetCart) {
              await getCart();
            }
          } else {
            dispatchRedux(pushNotification({ type: notificationTypes.ounceCorrectionPopup, data: { products: data?.products || [] } }))

            return data?.products || [];
          }
        }
        return null;
      }

      const loggedOutOunce = response?.data?.ounce;
      if (loggedOutOunce) {
        // loggedOutOunce.ounceObjParam = ounceObj;

        if (!preventLocalCartUpdation) {
          if (ounceId === loggedOutOunce.ounce_id) {
            if (onSuccess) {
              onSuccess();
            }
            StorageService.updateCart({ id: ounceId, isOunce: true, ounceProduct: loggedOutOunce });
            await getCart();
          } else {
            await setGetGuestCart({ product: loggedOutOunce, hideSuccess: true, onSuccess });
            // StorageService.addToCart(loggedOutOunce);
          }
        }

        return loggedOutOunce;
      }

      return null;
    } catch (error) {
      toast.error(error);
      return null;
    }
  }

  async function editOunce({ ounceId, ounceObj, hideSuccess, onSuccess }) {
    const ounceProduct = {
      asap: Number(ounceObj?.schedule_type === ScheduleType.ASAP),
      schedule: Number(ounceObj?.schedule_type === ScheduleType.Scheduled),
      ounceObj: { ...ounceObj }
    };

    // dispatch({ type: 'setLastAddedProduct', payload: { product: ounceProduct } });

    dispatchRedux(setLastAddedProduct({ product: ounceProduct }))

    const hasError = isInvalidOunce(ounceProduct);
    if (hasError) {
      return null;
    }

    try {
      const response = await axios({
        url: `${API_URL}/ounce/${ounceId}`,
        method: 'PATCH',
        data: ounceObj,
        hideSuccess
      });
      if (response) {
        const data = response?.data || {};
        if (data?.are_all_products_available) {
          if (onSuccess) {
            onSuccess();
          }
          await getCart();
        } else {
          dispatchRedux(pushNotification({ type: notificationTypes.ounceCorrectionPopup, data: { products: data?.products || [] } }))

          return data?.products || [];
        }
      }
    } catch (error) {
      toast.error(error);
    }

    return null;
  }

  async function deleteDebitCard(paymentObj) {
    try {
      await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/payments/${paymentObj.card_id}`,
        method: 'DELETE'
      });
    } catch (error) {
      toast.error(error);
    }
  }

  async function deleteCard(paymentObj) {
    try {
      await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/payments/ledgergreen/${paymentObj.card_id}`,
        method: 'DELETE'
      });
    } catch (error) {
      toast.error(error);
    }
  }

  async function deleteHistoryAddress(addressId) {
    try {
      await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/delivery_history/${addressId}`,
        method: 'DELETE'
      });
    } catch (error) {
      toast.error(error);
    }
  }

  async function saveCardDetail(payment) {
    await axios({
      url: `${process.env.NEXT_PUBLIC_LAMBDA_URL}/save_cards`,
      method: 'POST',
      data: {
        payment
      },
      hideSuccess: true
    });
  }
  const getAllProducts = async (postcode, type) => {
    const { data } = await axios({
      url: `${API_URL}/products/${type === SCHEDULE_TYPE_FUTURE ? 'schedule/' : ''}${postcode}`,
      method: 'GET'
    });

    return categoriesHelper(data?.categories || []);
  };

  const getAllProductsAndBundles = async (postcode, type) => {
    const [{ data: dataOne }, { data: dataTwo }] = await Promise.all([
      axios({
        url: `${API_URL}/products${type === SCHEDULE_TYPE_FUTURE ? '/schedule' : ''}/${postcode}`,
        method: 'GET'
      }),
      axios({
        url: `${API_URL}/products/${postcode}/bundles`,
        method: 'GET'
      })
    ]);

    const categoryOne = categoriesHelper(dataOne?.categories || []);
    const categoryTwo = categoriesHelper(dataTwo?.categories || []);

    return [...categoryOne, ...categoryTwo];
  };

  // product rating API starts here.

  const saveStarRatings = async (data) => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_LAMBDA_WAREHOUSE_URL}/product-rating`,
        method: 'POST',
        data,
        hideSuccess: true
      });

      return { data: res.data.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const getEffectAndRatingOptions = async (ratingId, productId) => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_LAMBDA_WAREHOUSE_URL}/product-rating/rating-option?star_rating=${ratingId}&product_id=${productId}`,
        method: 'GET',
        hideError: true
      });

      return { data: res.data.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const saveEffectsAndComments = async data => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_LAMBDA_WAREHOUSE_URL}/product-rating`,
        method: 'PUT',
        data
      });

      return { data: res.data.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const getUsersRatingOrderDetails = async order_id => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_LAMBDA_WAREHOUSE_URL}/product-rating/order/${order_id}`,
        method: 'GET',
        hideError: true
      });

      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const getOrderRatingDetails = async orderId => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_LAMBDA_WAREHOUSE_URL}/product-rating/order/${orderId}/rating-options`,
        method: 'GET',
        hideError: true
      });

      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };
  const getOrderDetails = async orderId => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/orders/${orderId}`,
        method: 'GET'
      });
      const { data } = res;
      return { data, error: null };
    } catch (error) {
      return { data: null, error: true };
    }
  };

  const uploadRatingImages = async (files) => {
    try {
      const formData = new FormData();

      files.map(file => {
        formData.append('files', file);
      });

      formData.append('isBase64Image', false);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/customers/upload_image/5`,
        method: 'POST',
        ...config,
        data: formData,
        hideSuccess: true
      });

      return { error: null, data: response.data };
    } catch (error) {
      return { error: true, data: null };
    }
  };

  async function manageProductOffer(product, status) {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;

    const { isLoggedIn } = userState.current

    if (isLoggedIn) {
      const cart_id = product.cart_id;
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/update/offer_status`,
        method: 'POST',
        data: {
          cart_id,
          status
        },
        hideError: false,
        hideSuccess: false
      });
      return get(response, 'data.success');
    }
    StorageService.updateCart({ id: product.product_id, isOfferAllowed: status });
    return true;
  }

  async function updateUserDetails(firstName, lastName, email) {
    // Saving to local Storage
    StorageService.setUserData({
      ...(StorageService.getUserData() || {}),
      name: `${firstName} ${lastName}`,
      first_name: firstName,
      last_name: lastName,
      email
    });

    const url = `${API_URL}/customers/profile`;

    const formData = new FormData();
    formData.append('firstname', firstName);
    formData.append('lastname', lastName);
    formData.append('email', email);

    const response = await axios({
      url,
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    });
    const responseData = await get(response, 'data');
    if (!responseData.success) {
      toast.error(get(response, 'data.message', 'Referral program for the user is blocked.'));
    }
  }

  const saveUpdatedAddress = async () => {
    // const { newAddressForProfile } = newstate.current;
    const { newAddressForProfile = {} } = otherState.current
    const { place_id, address } = newAddressForProfile;

    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/address/edit`,
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        data: { ...newAddressForProfile, placeid: place_id, address1: address }
      });
      if (res) {
        mutateAddressHistory();
      }
      return { data: res.data.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const makeDefaultAddress = async id => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/address/default`,
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        data: {
          address_id: id
        }
      });
      if (res) {
        mutateAddressHistory();
      }
      return { data: res.data.address, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const addAddress = async (addressObj, hideSuccess = false) => {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;

    const { isLoggedIn } = userState.current

    if (!addressObj) {
      // const { newAddressForProfile } = newstate.current;
      const { newAddressForProfile = {} } = otherState.current
      addressObj = { ...newAddressForProfile, placeId: newAddressForProfile.place_id };
    }
    const {
      placeId,
      address_id,
      address,
      latitude,
      longitude,
      postcode,
      city,
      state,
      country,
      address_type,
      address_type_name,
      street_name,
      street_number
    } = addressObj;
    const payload = {
      address,
      latitude,
      longitude,
      address_id,
      city,
      country,
      postcode,
      state,
      placeId,
      streetNumber: street_number || null,
      streetName: street_name || null,
      addressType: address_type,
      addressTypeName: address_type_name
    };
    // dispatch({ type: 'updateLocation', payload: { ...payload } });
    dispatchRedux(updateLocation({ ...payload }))
    // dispatch({ type: 'saveLocation', payload: { ...payload } });
    dispatchRedux(saveLocation({ ...payload }))
    try {
      if (isLoggedIn) {
        const res = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/address/add`,
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          data: {
            placeid: placeId,
            address_id,
            address1: address,
            address_type,
            address_type_name,
            latitude,
            longitude,
            postcode,
            state,
            city,
            country
          },
          hideSuccess,
          hideError: true
        });
        if (res) {
          mutateAddressHistory();
        }
      }
      return { data: res.data.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  const deleteAddress = async id => {
    try {
      const res = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/address/${id}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json'
        }
      });
      if (res) {
        mutateAddressHistory();
      }
      return { data: res.data.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };
  async function fetchNewAsapDeliveryDetails(payload) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/pre_fetch/asap_delivery_details`,
        method: 'GET',
        params: payload,
        hideError: false,
        hideSuccess: false
      });

      const { deliveryDetails, token: asapDeliveryToken } = response?.data?.result || {};

      if (deliveryDetails && asapDeliveryToken) {
        StorageService.setItemSession('NEW_ASAP_DELIVERY_DETAILS', deliveryDetails);
        StorageService.setItemSession('NEW_ASAP_DELIVERY_TOKEN', asapDeliveryToken);
      } else {
        StorageService.removeItemSession('NEW_ASAP_DELIVERY_DETAILS');
        StorageService.removeItemSession('NEW_ASAP_DELIVERY_TOKEN');
      }
    } catch (error) {
      throw error;
    }
  }

  async function getIssueList(orderId) {
    try {
      const response = await axios({
        url: `${ACS_API_URL}customer/issue-list/${orderId}`,
        method: 'GET',
        hideSuccess: true,
        hideError: true
      });
      return get(response, 'data');

    } catch (error) {
      return { error };
    }
  }

  async function getPreviousIssueList(orderId) {
    try {
      const response = await axios({
        url: `${ACS_API_URL}customer/reported-list?order_id=${orderId}&offset=0&items=40&status=3&page=1`,
        method: 'GET'
      });
      return get(response, 'data');
    } catch (error) {
      return { error };
    }
  }

  async function getComplaintHistory(complaintId) {
    try {
      const response = await axios({
        url: `${ACS_API_URL}customer/history/${complaintId}`,
        method: 'GET'
      });
      return get(response, 'data');
    } catch (error) {
      return { error };
    }
  }

  async function addChatBotFeedback(data, orderId) {
    try {
      const response = await axios({
        url: `${ACS_API_URL}customer/feedback/${orderId}`,
        method: 'POST',
        data,
        hideSuccess: true,
        hideError: true
      });
      return get(response, 'data');
    } catch (error) {
      return {
        error
      };
    }
  }

  async function uploadChatBotImages(file, onChangeProgress) {
    try {
      const url = `${API_URL}/customers/upload_image/6`;
      const formData = new FormData();
      formData.append('files', file);
      formData.append('isBase64Image', false);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const uploadProgress = (progressEvent.loaded * 100) / progressEvent.total;
          if (onChangeProgress) {
            onChangeProgress(uploadProgress);
          }
        }
      };
      return await axios({
        url,
        method: 'POST',
        ...config,
        data: formData
      });
      // post(url, formData, config);
    } catch (error) {
      process.env.NEXT_PUBLIC_ENV === "development" && console.log('error', error);
      setRollbarWarning('API.js_uploadChatBotImages_catch', error);
      console.error('API.js_uploadChatBotImages_catch', error.response);
    }
  }

  async function saveChatBotHistory(data) {
    try {
      const response = await axios({
        url: `${ACS_API_URL}customer/history`,
        method: 'POST',
        data,
        hideSuccess: true,
        hideError: true
      });
      return get(response, 'data');
    } catch (error) {
      return { error };
    }
  }

  async function referralGiftcard({ recipientFirstName, email, phone, countryCode, productId, gc_image, is_resend = false }) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/referral/giftcard`,
        method: 'POST',
        data: {
          recipientName: recipientFirstName,
          email,
          phone,
          countryCode,
          productId,
          gc_image,
          is_resend
        },
        hideError: false,
        hideSuccess: false
      });
      if (response) {
        mutateReferralGiftcard();
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async function referralFollowUp({ giftCardCode }) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/referral/followup`,
        method: 'POST',
        data: {
          gift_card_code: giftCardCode
        },
        hideError: false,
        hideSuccess: false
      });
      if (response) {
        mutateReferralGiftcard();
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async function getLedgerGreenAgreementDetails({ amount }) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/ledgergreen/agreement`,
        method: 'POST',
        data: {
          amount: amount.toString()
        },
        hideSuccess: true
      });
      return get(response, 'data');
    } catch (error) {
      return false;
    }
  }

  async function getComplianceFees({ newcard = false, cardNumber }) {
    if (cardNumber) {
      try {
        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/ledgergreen/compliance_fee`,
          method: 'POST',
          data: {
            ...(!newcard ? { user_card_id: cardNumber } : { card_number: cardNumber.replaceAll(' ', '') })
          },
          hideSuccess: true,
          hideError: true
        });
        const data = get(response, 'data');
        // dispatch({ type: 'setLedgerGreenComplianceFee', payload: data?.compliance_fee });
        dispatchRedux(setLedgerGreenComplianceFee(data?.compliance_fee))
      } catch (error) {
        // dispatch({ type: 'setLedgerGreenComplianceFee', payload: null });
        dispatchRedux(setLedgerGreenComplianceFee(null))
        setRollbarWarning('API.js_getComplianceFees', error);
        console.error('API.js_getComplianceFees', error);
      } finally {
        await getCart();
      }
    }
  }
  const getProductDetailsList = async (productList, postcode) => {
    const zipCode = await checkZipCodeStatus({ ...StorageService.getAddressLocalStorage(), postcode });
    if (!zipCode.validZipcode) {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.deliverAddressPopup }))
      return {
        products: StorageService.getCompareDataList()
      };
    }
    const tempData = JSON.stringify(productList);
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/product_details/${postcode}`,
        method: 'GET',
        params: {
          master_product_ids: tempData
        }
      });
      return response.data?.success
        ? response.data
        : {
          products: StorageService.getCompareDataList()
        };
    } catch (error) {
      return {
        products: StorageService.getCompareDataList()
      };
    }
  };

  async function getSaveForLaterItems(page = 1) {
    // dispatch({ type: 'setSaveForLaterItemsLoading', payload: true });
    const {
      // user: { isLoggedIn },
      shop: { saveForLaterFilters: filters },
    } = newstate.current;

    const { isLoggedIn } = userState.current


    let filterApplied = false;
    if (filters) {
      filters.forEach(element => {
        if (
          element.type === 'sort_key' &&
          (!element.values[0].checked || (element.values[0].checked && element.values[0]?.intended)) //
        ) {
          filterApplied = true;
        }
        if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
          filterApplied = true;
        }
        if (element.name === 'priceRange' && element.isSet == true) {
          filterApplied = true;
        }
      });
    }

    const filtersQueryString = getFilterQueryString(filters);

    try {
      if (isLoggedIn) {
        // API call
        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/saved_for_later/product?per_page_limit=12&page=${page}&${filtersQueryString}`,
          method: 'GET',
          hideSuccess: true,
          hideError: true
        });

        let {
          data: {
            data,
            meta: { total, filter_total }
          }
        } = response;

        if (page > 1) {
          data = [...saveLaterDataRef, ...data];
        } else {
          saveLaterDataRef = [...data];
        }
        // dispatch to state
        dispatch({ type: 'setSaveForLaterItems', payload: data });

        dispatchRedux(setSaveForLaterItems(data))
        saveLaterDataRef = [...data];
        dispatch({ type: 'setSaveForLaterItemsCount', payload: total });

        dispatchRedux(setSaveForLaterItemsCount(total))
        dispatch({ type: 'setFilteredItemsCount', payload: filter_total });

        dispatchRedux(setFilteredItemsCount(filter_total))
      } else {
        const saveForLaterData = StorageService.getSaveForLaterItems({ includingDisabled: true });
        const requestData = {
          product_id: [],
          bundle_id: []
        };
        saveForLaterData.forEach(item => {
          if (item.bundle_id) {
            requestData.bundle_id.push(item.bundle_id);
          } else if (item.product_details_id) {
            requestData.product_id.push(item.product_details_id);
          }
        });
        if (!requestData?.bundle_id.length) delete requestData.bundle_id;
        if (!requestData?.product_id.length) delete requestData.product_id;

        const filteredData = await getFilteredProducts(requestData, filtersQueryString);

        if (
          !filterApplied &&
          (saveForLaterData?.length != filteredData?.length ||
            saveForLaterData?.length != StorageService.getSaveForLaterItems()?.length)
        ) {
          // update local stored items if any items disabled from warehouse
          StorageService.updateSaveForLaterItems(filteredData);
        }
        // dispatch items to state
        dispatch({ type: 'setSaveForLaterItems', payload: filteredData });
        dispatchRedux(setSaveForLaterItems(filteredData))
        dispatch({ type: 'setSaveForLaterItemsCount', payload: StorageService.getSaveForLaterItems()?.length });
        dispatchRedux(setSaveForLaterItemsCount(StorageService.getSaveForLaterItems()?.length))
        dispatch({ type: 'setFilteredItemsCount', payload: filteredData?.length });
        dispatchRedux(setFilteredItemsCount(filteredData?.length))
      }
    } catch (error) {
      console.error('Shop.js_getSaveForLaterItems', error);
    } finally {
      // dispatch({ type: 'setSaveForLaterItemsLoading', payload: false });
    }
  }

  async function addToSaveForLater({
    items = [],
    hideSuccess: hideOnSuccess = false,
    hideError = false,
    updateIsSavedForLaterItemAddedOrRemoved = true,
    type = 'home',
    isMostPopularProduct = false,
    fromPage = '',
    isFeaturedProduct = false,
    callSegmentEvent = true
  } = {}) {
    // accepts array of items
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;
    const { isLoggedIn } = userState.current
    dispatch({ type: 'setSaveForLaterItemsLoading', payload: true });

    dispatchRedux(setSaveForLaterItemsLoading(true))
    try {
      if (isLoggedIn) {
        // API call
        const requestData = {
          product_id: [],
          bundle_id: []
        };

        items.forEach(item => {
          if (item.bundle_id) {
            requestData.bundle_id.push(item.bundle_id);
          } else if (item.product_details_id) {
            requestData.product_id.push(item.product_details_id);
          }
        });

        if (!requestData?.bundle_id.length) delete requestData.bundle_id;
        if (!requestData?.product_id.length) delete requestData.product_id;

        if (requestData?.bundle_id || requestData?.product_id) {
          const response = await axios({
            url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/saved_for_later/product`,
            method: 'POST',
            data: requestData,
            hideSuccess: true,
            hideError
          });
          if (!hideOnSuccess && response.data.success) {
            wishlistToast(`Product Moved to Saved For Later list`);
          }
        }
      } else {
        const savedIds = StorageService.getSaveForLaterItems({ includingDisabled: true })?.map(item => item.product_details_id);
        items.forEach(item => {
          if (savedIds.includes(item.product_details_id)) return;
          StorageService.addToSaveForLater(item);
        });
        if (!hideOnSuccess) {
          wishlistToast(`Product Moved to Saved For Later list`);
        }
      }

      if (updateIsSavedForLaterItemAddedOrRemoved) {
        dispatch({ type: 'setIsSavedForLaterItemAddedOrRemoved', payload: true });
        dispatchRedux(setIsSavedForLaterItemAddedOrRemoved(true));
      }

      getCart();
    } catch (error) {
      console.error('API.js_addToSaveForLater', error);
    } finally {
      dispatch({ type: 'setSaveForLaterItemsLoading', payload: false });

      dispatchRedux(setSaveForLaterItemsLoading(false))

      const product = items[0];
      if (product && callSegmentEvent) {
        trackEvent('Product added Save for Later', {
          recommendation_type: product.recommendation_type,
          product_id: product?.bundle_id ? product.bundle_id : product.master_product_id,
          sku: product.bundle_id ? product.bundle_id : product.master_product_id,
          best_seller: isMostPopularProduct,
          best_seller_type: fromPage,
          type,
          is_featured_product: isFeaturedProduct,
          category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
          category: product.full_shop_category_name || '',
          name: product.name || product.product_name,
          product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
          brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
          variant: `${product.category_weight} ${product.category_unit}`,
          price: product.product_price || product.price,
          original_price: product.price_without_deal || product.price,
          strain_type: product.product_strain_type_name || '',
          position: 1,
          url: `${origin}/product/${product.slug}`,
          image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge
        });
      }
    }
  }

  async function removeFromSaveForLater({
    item = {},
    type = 'home',
    isMostPopularProduct = false,
    fromPage = '',
    isFeaturedProduct = false
  }) {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;
    const { isLoggedIn } = userState.current
    dispatch({ type: 'setSaveForLaterItemsLoading', payload: true });

    dispatchRedux(setSaveForLaterItemsLoading(true))
    try {
      if (isLoggedIn) {
        // API call
        let productType;
        let id;

        if (item?.bundle_id) {
          productType = 'bundle';
          id = item.bundle_id;
        } else if (item?.product_details_id) {
          productType = 'product';
          id = item.product_details_id;
        }

        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/saved_for_later/${productType}/${id}`,
          method: 'DELETE',
          hideSuccess: true,
          hideError: true
        });

        if (response.data.success) {
          wishlistToast(`Product Removed from Saved For Later list`);
        }
      } else {
        StorageService.deleteSaveForLaterProduct(item);
        wishlistToast(`Product Removed from Saved For Later list`);
      }
      dispatch({ type: 'setIsSavedForLaterItemAddedOrRemoved', payload: true });
      dispatchRedux(setIsSavedForLaterItemAddedOrRemoved(true));
    } catch (error) {
      console.error('API.js_removeFromSaveForLater', error);
    } finally {
      dispatch({ type: 'setSaveForLaterItemsLoading', payload: false });
      dispatchRedux(setSaveForLaterItemsLoading(false))

      const product = { ...item };
      if (product) {
        trackEvent('Save for Later Product removed', {
          recommendation_type: product.recommendation_type,
          product_id: product?.bundle_id ? product.bundle_id : product.master_product_id,
          sku: product.bundle_id ? product.bundle_id : product.master_product_id,
          best_seller: isMostPopularProduct,
          best_seller_type: fromPage,
          type,
          is_featured_product: isFeaturedProduct,
          category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
          category: product.full_shop_category_name || '',
          name: product.name || product.product_name,
          product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
          brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
          variant: `${product.category_weight} ${product.category_unit}`,
          price: product.product_price || product.price,
          original_price: product.price_without_deal || product.price,
          strain_type: product.product_strain_type_name || '',
          position: 1,
          url: `${origin}/product/${product.slug}`,
          image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge
          // convertedFromWishlist: true/false
        }, true);
      }
    }
  }

  async function getWishListFavourites(page = 1) {
    dispatch({ type: 'setFavouriteItemsLoading', payload: true });
    dispatchRedux(setFavouriteItemsLoading(true))
    const {
      // user: { isLoggedIn },
      shop: { favouriteFilters: filters },
      // wishlist: { favouriteItems = [] }
    } = newstate.current;
    const { favouriteItems = [] } = wishlistState.current
    const { isLoggedIn } = userState.current

    let filterApplied = false;
    if (filters) {
      filters.forEach(element => {
        if (
          element.type === 'sort_key' &&
          (!element.values[0].checked || (element.values[0].checked && element.values[0]?.intended))
        ) {
          filterApplied = true;
        }
        if (element.type === 'search' && filter(element.values, { checked: true }).length > 0) {
          filterApplied = true;
        }
        if (element.name === 'priceRange' && element.isSet == true) {
          filterApplied = true;
        }
      });
    }

    const filtersQueryString = getFilterQueryString(filters);

    try {
      if (isLoggedIn) {
        // API call
        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/favourite/product?per_page_limit=12&page=${page}&${filtersQueryString}`,
          method: 'GET',
          hideSuccess: true,
          hideError: true
        });

        let {
          data: {
            data,
            meta: { total, filter_total }
          }
        } = response;

        if (page > 1) {
          data = [...favouriteItems, ...data];
        }

        // dispatch to state
        dispatch({ type: 'setFavouriteItems', payload: data });
        dispatchRedux(setFavouriteItems(data));
        dispatch({ type: 'setFavouriteItemsCount', payload: total });
        dispatchRedux(setFavouriteItemsCount(total))
        dispatch({ type: 'setFilteredItemsCount', payload: filter_total });
        dispatchRedux(setFilteredItemsCount(filter_total))
      } else {
        const favouritesData = StorageService.getFavourites({ includingDisabled: true });

        const requestData = {
          product_id: [],
          bundle_id: []
        };
        favouritesData.forEach(item => {
          if (item.bundle_id) {
            requestData.bundle_id.push(item.bundle_id);
          } else if (item.product_details_id) {
            requestData.product_id.push(item.product_details_id);
          }
        });
        if (!requestData?.bundle_id.length) delete requestData.bundle_id;
        if (!requestData?.product_id.length) delete requestData.product_id;

        const filteredData = await getFilteredProducts(requestData, filtersQueryString);

        if (
          !filterApplied &&
          (favouritesData?.length != filteredData?.length || favouritesData?.length != StorageService.getFavourites()?.length)
        ) {
          // update local stored items if any items disabled from warehouse
          StorageService.updateFavouriteItems(filteredData);
        }

        // dispatch items to state
        dispatch({ type: 'setFavouriteItems', payload: filteredData });
        dispatchRedux(setFavouriteItems(filteredData));
        dispatch({ type: 'setFavouriteItemsCount', payload: StorageService.getFavourites()?.length });
        dispatchRedux(setFavouriteItemsCount(StorageService.getFavourites()?.length))
        dispatch({ type: 'setFilteredItemsCount', payload: filteredData?.length });
        dispatchRedux(setFilteredItemsCount(filteredData?.length))
      }
    } catch (error) {
      console.error('Shop.js_getWishListFavourites', error);
    } finally {
      dispatch({ type: 'setFavouriteItemsLoading', payload: false });
      dispatchRedux(setFavouriteItemsLoading(false))
    }
  }

  async function addToFavourites({
    items = [],
    hideSuccess: hideOnSuccess = false,
    hideError = false,
    updateIsFavouriteItemAddedOrRemoved = true,
    isMostPopularProduct = false,
    isFeaturedProduct = false,
    callSegmentEvent = true,
    type = 'home',
    fromPage = ''
  } = {}) {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;
    const { isLoggedIn } = userState.current
    try {
      if (isLoggedIn) {
        // API call
        const requestData = {
          product_id: [],
          bundle_id: []
        };

        items.forEach(item => {
          if (item.bundle_id) {
            requestData.bundle_id.push(item.bundle_id);
          } else if (item.product_details_id) {
            requestData.product_id.push(item.product_details_id);
          }
        });

        if (!requestData?.bundle_id.length) delete requestData.bundle_id;
        if (!requestData?.product_id.length) delete requestData.product_id;

        if (requestData?.bundle_id || requestData?.product_id) {
          const response = await axios({
            url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/favourite/product`,
            method: 'POST',
            data: requestData,
            hideSuccess: true,
            hideError
          });

          if (!hideOnSuccess && response.data.success) {
            wishlistToast(`Product Successfully added to your Favorites`);
          }
        }
      } else {
        items.map(item => StorageService.addToFavourites(item));
        if (!hideOnSuccess) {
          wishlistToast(`Product Successfully added to your Favorites`);
        }
      }

      if (updateIsFavouriteItemAddedOrRemoved) {
        dispatch({ type: 'setIsFavouriteItemAddedOrRemoved', payload: true });
        dispatchRedux(setIsFavouriteItemAddedOrRemoved(true));
      }

      // update allFavouriteItemIds in context
      // if (isLoggedIn) {
      //   dispatch({ type: 'setAllFavouriteItemIds', payload: [...allFavouriteItemIds, items[0]?.product_details_id] });
      // }

      return true;
    } catch (error) {
      console.error('API.js_addToFavourites', error);
      return false;
    } finally {
      const product = items[0];
      if (product && callSegmentEvent) {
        trackEvent('Product added favorite', {
          recommendation_type: product.recommendation_type,
          product_id: product?.bundle_id ? product.bundle_id : product.master_product_id,
          sku: product.bundle_id ? product.bundle_id : product.master_product_id,
          best_seller: isMostPopularProduct,
          best_seller_type: fromPage,
          type,
          is_featured_product: isFeaturedProduct,
          category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
          category: product.full_shop_category_name || '',
          name: product.name || product.product_name,
          product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
          brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
          variant: `${product.category_weight} ${product.category_unit}`,
          price: product.product_price || product.price,
          original_price: product.price_without_deal || product.price,
          strain_type: product.product_strain_type_name || '',
          position: 1,
          url: `${origin}/product/${product.slug}`,
          image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge
        });
      }
    }
  }

  async function removeFromFavourites({
    item = {},
    isMostPopularProduct = false,
    isFeaturedProduct = false,
    type = 'home',
    fromPage = ''
  }) {
    // const {
    //   user: { isLoggedIn }
    // } = newstate.current;
    const { isLoggedIn } = userState.current
    try {
      if (isLoggedIn) {
        // API call
        let productType;
        let id;

        if (item?.bundle_id) {
          productType = 'bundle';
          id = item.bundle_id;
        } else if (item?.product_details_id) {
          productType = 'product';
          id = item.product_details_id;
        }

        const response = await axios({
          url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/favourite/${productType}/${id}`,
          method: 'DELETE',
          hideSuccess: true,
          hideError: true
        });

        if (response.data.success) {
          wishlistToast(`Product Removed from your Favorites`);
        }
      } else {
        StorageService.deleteFavouriteProduct(item);
        wishlistToast(`Product Removed from your Favorites`);
      }

      dispatch({ type: 'setIsFavouriteItemAddedOrRemoved', payload: true });
      dispatchRedux(setIsFavouriteItemAddedOrRemoved(true));
      // update allFavouriteItemIds in context
      // if (isLoggedIn) {
      //   dispatch({ type: 'setAllFavouriteItemIds', payload: allFavouriteItemIds.filter(id => id != item?.product_details_id) });
      // }

      return true;
    } catch (error) {
      console.error('API.js_removeFromFavourites', error);
      return false;
    } finally {
      const product = { ...item };
      if (product) {
        trackEvent('Favorites Product removed', {
          recommendation_type: product.recommendation_type,
          product_id: product?.bundle_id ? product.bundle_id : product.master_product_id,
          sku: product.bundle_id ? product.bundle_id : product.master_product_id,
          best_seller: isMostPopularProduct,
          best_seller_type: fromPage,
          type,
          is_featured_product: isFeaturedProduct,
          category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
          category: product.full_shop_category_name || '',
          name: product.name || product.product_name,
          product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
          brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
          variant: `${product.category_weight} ${product.category_unit}`,
          price: product.product_price || product.price,
          original_price: product.price_without_deal || product.price,
          strain_type: product.product_strain_type_name || '',
          position: 1,
          url: `${origin}/product/${product.slug}`,
          image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge
        });
      }
    }
  }

  // API call for applying filter over set of products non LoggedIn State
  async function getFilteredProducts(products, filterString = '') {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/non-logged-in/products?${filterString}`,
        method: 'POST',
        data: products,
        hideSuccess: true,
        hideError: true
      });

      const {
        data: { data }
      } = response;

      return data || [];
    } catch (error) {
      console.error('API.js_getFilteredProducts', error);
    }
  }
  async function getFullShopCategoriesList(postcode) {
    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/categories/all_categories/${postcode}`,
        method: 'GET',
        hideSuccess: true,
        hideError: true
      })
      return response?.data?.full_shop_categories ?? []
    } catch (error) {
      return []
    }
  }

  // API call to get wishlisted product Ids
  // async function getWishlistedProductIds() {
  //   try {
  //     const response = await axios({
  //       url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/wishlist/list/product-id`,
  //       method: 'GET'
  //     });

  //     const {
  //       data: { data }
  //     } = response;

  //     dispatch({ type: 'setAllFavouriteItemIds', payload: data?.fav?.product || [] });
  //   } catch (error) {
  //     console.error('API.js_getWishlistedProductIds', error);
  //   }
  // }

  async function getTopSearches() {
    try {
      let today = new Date();
      today.setMonth(today.getMonth() - 3);
      let day = today.getDate();
      day = day > 9 ? day : `0${day}`;
      let month = today.getMonth() + 1;
      month = month > 9 ? month : `0${month}`;
      let year = today.getFullYear();
      let priorDate = year + '-' + month + '-' + day;
      const response = await axios({
        url: `https://analytics.algolia.com/2/searches?index=${appConfig.SEARCH_INDICE}&limit=20&offset=1&&startDate=${priorDate}`,
        method: 'GET',
        headers: {
          'X-Algolia-API-Key': appConfig.SEARCH_ADMIN_KEY,
          'X-Algolia-Application-Id': appConfig.SEARCH_CONTAINER
        }
      });
      return response?.data?.searches || null;
    } catch (error) {
      throw new Error();
      return { error };
    }
  }

  return {
    getIssueList,
    getComplaintHistory,
    getPreviousIssueList,
    saveChatBotHistory,
    addChatBotFeedback,
    uploadChatBotImages,
    manageProductOffer,
    getPlaceId,
    requestOTP,
    confirmOtp,
    uploadPrescription,
    deletePrescription,
    uploadIDStatus,
    checkZipCodeStatus,
    requestEmailOTP,
    confirmEmailOTP,
    applyCoupon,
    checkProductAvailability,
    switchPayment,
    editAmbassadorBankDetails,
    sendAmbassadorBankDetails,
    addAmbassadorCardDetails,
    addReferralCode,
    deleteReferralCode,
    encashReferralAmount,
    switchCartItems,
    emailSubscribe,
    enterPACNumber,
    verifyCustomerSignature,
    rateDriver,
    getMarketingDetails,
    validateCoupon,
    syncLocalCartData,
    setReferralLink,
    getReferralBranchLinkData,
    reorder,
    CCPAsubmit,
    CCPAconfirmOTP,
    setDefaultCard,
    getCart,
    setGetGuestCart,
    subscribeToDeals,
    fetchSiteData,
    subscribeNewsletter,
    getCurrentCustomerNumber,
    createStrongholdAccount,
    createKindTapAccount,
    createStrongholdToken,
    deleteStrongholdBankAccount,
    signupToDrive,
    driverReferral,
    fetchActiveLocations,
    logOut,
    FnFOptIn,
    fetchCashBacks,
    confirmMmicUser,
    applyExistingGiftCards,
    redeemSingleGiftCard,
    getWeedyMedical,
    createOunce,
    editOunce,
    saveStarRatings,
    getEffectAndRatingOptions,
    saveEffectsAndComments,
    getUsersRatingOrderDetails,
    getOrderRatingDetails,
    saveCardDetail,
    getAllProducts,
    getAllProductsAndBundles,
    referralGiftcard,
    referralFollowUp,
    getOrderDetails,
    uploadRatingImages,
    getProductDetailsBySlug,
    deleteDebitCard,
    authenticateProfile,
    updateUserDetails,
    saveUpdatedAddress,
    makeDefaultAddress,
    addAddress,
    deleteAddress,
    fetchNewAsapDeliveryDetails,
    deleteHistoryAddress,
    getRecommendedProductsBySlug,
    getRecommendedProductsByIdASAP,
    getRecommendedProductsByIdSchedule,
    getSimilarProducts,
    getLedgerGreenAgreementDetails,
    deleteCard,
    getComplianceFees,
    getInvoiceDataForFutureCart,
    getTeslaCampaignInfo,
    getDailyDealsProducts,
    getSaveForLaterItems,
    addToSaveForLater,
    removeFromSaveForLater,
    getWishListFavourites,
    addToFavourites,
    removeFromFavourites,
    getProductDetailsList,
    getUpsellPopularProducts,
    getUpsellProducts,
    getUpsellRecommendedProducts,
    getTopSearches,
    getFullShopCategoriesList
    // getWishlistedProductIds
  };
};

export default useAPI;